import Axios             from "axios";
import Vue               from "vue";
import {getToken}        from "@/store/modules/user";
import store             from "@/store";
import * as BufferModule from "buffer";
import ls                from "localstorage-slim";
import qs                from "qs";
import router            from "@/router";
import {i18n}            from "@/app";
import {messageWarning}  from "@/mixins/notify";
import {ProgressLocaleInitialize} from "@/utils/i18n-process";

// console.log(BufferModule);
// const {Buffer} = BufferModule;
const baseRequest = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API || "/f9api",
  timeout: 500000,
  withCredentials: true,
});

export function toQueryParams(params, data) {
  params && Object.keys(params).forEach(key => {
    if (params[key] instanceof Array) {
      params[key] = JSON.stringify(params[key]);
    }
  });
  data && Object.keys(data).forEach(key => {
    if (data[key] instanceof Array) {
      data[key] = JSON.stringify(data[key]);
    } else if (typeof data[key] === "object" && data[key] !== null && data[key] !== undefined) {
      data[key] = JSON.stringify(data[key]);
    }
  });
  params = formatQuery(params);
  params.customer = true;

  if (store.getters.token?.role === "admin") {
    params && (params.customer = false);
  }

  params = {
    hash: hashRequest(params, data),
    ...params,
  };

  return params;
}

// todo: 可尝试将所有API返回的数据做统一转换， 提供一项默认转换，以及可自定义转换

/** 请求前拦截 **/
baseRequest.interceptors.request.use(config => {
  config.params = toQueryParams(config.params, config.data);
  // config.params.customer = true;

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
    config.transformRequest = [data => qs.stringify(data)];
  }

  // if (store.getters.token?.role === "admin") {
  //   config.params && (config.params.customer = false);
  // }

  // config.params = {
  //   hash: hashRequest(config.params, config.data),
  //   ...config.params,
  // };

  return config;
});

/** 请求后返回前拦截 **/
baseRequest.interceptors.response.use(async response => {
  const config = response.config;
  const cache = config.cache;

  if (cache?.type === "local") {
    const {type, ...options} = cache;
    if (response.data?.success !== false) {
      ls.set(config.url, response.data, options);
    }
  }

  const data = response.data;
  //只做一次提示即可
  if (data?.success === false && data?.message.indexOf(i18n.t("RequestMessage.TokenError")) >= 0 && getToken()) {
    await store.dispatch("user/LOG_OUT", "local");
    messageWarning(i18n.t("RequestMessage.HintReLoginMessage"));
    router.replace({
      name: "Home",
    });
  }

  ProgressLocaleInitialize(data, {
    debugger: config.debugger,
    url: config.url,
  });

  return data;
}, error => {
  console.error(error);
  //todo: 做个全局提示

  if (error?.message === "Network Error") {
    error.message = "网络连接失败";
  }

  return {
    success: false,
    message: error?.message || "请求出现错误，服务器可能出错或是网络波动",
  };
});

class RequestManage {
  stack = [];
  map = new Map();

  push(request) {
    this.stack.push(request);
    request.finally(() => {
      const index = this.stack.indexOf(request);

      this.stack.splice(index, 1);
    });
  }

  mapSet(key, request) {
    this.map.set(key, request);

    request.finally(() => {
      try {
        this.map.delete(key);
      } catch (e) {
      }
    });
  }

  mapGet(key) {
    return this.map.get(key);
  }

  mapDelete(key){
    return this.map.delete(key);
  }

  clear(message = i18n.t("RequestMessage.AbortMessage")) {
    this.stack.forEach(request => {
      try {
        request.cancel(new Error(message));
      } catch (e) {

      }
    });
  }
}

export const requestManage = new RequestManage();

function request(config, ...args) {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  config.cancelToken = source.token;

  if (config.cache?.type === "local" && !(process.env.NODE_ENV === "development")) {
    const data = ls.get(config.url);
    if (data && data?.success !== false) {
      return data;
    }
  }


  if (config?.once === true) {
    // console.log(requestManage.mapGet(config.url));
    if (requestManage.mapGet(config.url)) {
      return requestManage.mapGet(config.url);
    }
  }

  const requestSlot = baseRequest(config, ...args);

  requestSlot.cancel = source.cancel;
  requestManage.push(requestSlot);
  requestManage.mapSet(config.url, requestSlot);

  requestSlot.finally(() => {
    try {
      requestManage.mapDelete(config.url);
    }
    catch {

    }
  });
  //有效请求取决于 最后一次 或是 第一次，默认最后一次
  // const isLast = config.once === true || config.once === "last";
  // if (isLast || config.once === "first") {
  //   requestManage.push(requestSlot);
  // }


  // source.cancel("我取消请求了！");

  // requestSlot.cancel("我取消请求了！");

  return requestSlot
}

Vue.prototype.$request = request;
export default request;

function formatQuery(params) {
  const obj = params || {};
  const moment = require("moment-timezone");
  const token = getToken();

  for (const index in obj) {
    if (obj[index] === null) {
      obj[index] = "";
    }
  }
  if (token) {
    obj["tokenid"] = token.Id;
  }
  obj["companyid"] = 0;
  obj["timestamp"] = moment().tz("Asia/Shanghai").format("YYYY-MM-DD HH:mm:ss");
  return obj;
}

function toQueryString(items) {
  const qs = [];
  items.forEach((item) => {
    qs.push(`${item.name}=${item.value}`);
  });
  return qs.join("&");
}

function sortAscending(items) {
  items.sort((item1, item2) => {
    if (item1.name > item2.name) {
      return 1;
    }
    if (item1.name < item2.name) {
      return -1;
    }
    return 0;
  });
  return items;
}

function parseData(obj) {
  const items = [];
  if (obj) {
    Object.keys(obj).forEach((key) => {

      if (![undefined, null].includes(obj[key])) {
        items.push({
          name: key.toLowerCase(),
          value: obj[key],
        });
      }
    });
  }
  return items;
}

export function hashRequest(params, data) {
  const publickey = "7D0BBA28-E239-4B5E-B7F2-EFD5537E9B8A";
  // const publickey = "7D0BBA28-E239-4B5E-B7F2-EFD5537E9B8A";
  const queryItems = parseData(params);
  const formItems = parseData(data);
  const clonedQueryItems = JSON.parse(JSON.stringify(queryItems));
  const orderedQueryItems = sortAscending(clonedQueryItems);

  const clonedFormItems = JSON.parse(JSON.stringify(formItems));
  const orderedFormItems = sortAscending(clonedFormItems);
  const originalParts = [
    toQueryString(orderedQueryItems),
    toQueryString(orderedFormItems),
    publickey,
  ];
  let originalContent;
  if (data && data.constructor.name === "FormData") {
    originalContent = originalParts.join("||");
  } else {
    originalContent = originalParts.filter(x => !!x).join("||");
  }

  return computeHash(originalContent);
}

function computeHash(content) {
  const sha1 = require("sha1");
  const hashBytes = sha1(content, {asBytes: true});
  const hashBuf = new BufferModule.Buffer(hashBytes);
  return hashBuf.toString("base64");
}

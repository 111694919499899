<template>
  <el-tooltip :content="hintText" :disabled="!hint" placement="top">
    <el-checkbox
      v-model="dynamic"
      :label="label"
      :true-label="trueLabel"
      :false-label="falseLabel"
      :disabled="readonly || disabled"
      :class="{ readonly: readonly, 'round-type': round, 'oms-checkbox': !round }"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </el-checkbox>
  </el-tooltip>
</template>

<script>
import { model } from '@/mixins'

export default {
  name: 'ElCheckboxExtend',
  mixins: [model],
  props: {
    label: [String, Number],
    trueLabel: [String, Number],
    falseLabel: [String, Number],
    readonly: Boolean,
    round: Boolean,
    disabled: Boolean,
    hint: Boolean,
    hintText: String
  }
}
</script>

<style lang="scss">
.oms-checkbox {
  &.el-checkbox {
    .el-checkbox__input {
      display: none;
    }
    .el-checkbox__label {
      padding: 0 30px;
      border: 1px solid #aaa;
      transition: 0.2s linear;
      font-size: calc(16px - var(--default_font-size_offset));
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    &.is-checked {
      .el-checkbox__label {
        border: 1px solid $FONT_COLOR;
        color: $FONT_COLOR;
      }
    }
  }
  &.readonly {
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      cursor: auto;
    }
  }
}

.round-type {
  .el-checkbox__input {
    display: none;
  }
  .el-checkbox__label {
    position: relative;
    padding-left: 25px;
    font-size: calc(14px - var(--default_font-size_offset));
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: 0;
      transition: background-image .2s ease-in-out;
      background-image: url('@/assets/images/checkbox-select-no@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.is-checked {
    .el-checkbox__label {
      color: #333 !important;
      &::before {
        background-image: url('@/assets/images/checkbox-select@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  &.readonly {
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      cursor: auto;
    }
  }
}
</style>

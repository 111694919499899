<template>
  <div v-clickoutside="handleClose">
    <el-input v-model="inputValue" v-bind="InputProps"
              ref="reference"
              @focus="onHandleFocus"
              @input="onCheckOptions"
              @keydown.native.down.stop.prevent="handleNavigate('next')"
              @keydown.native.up.stop.prevent="handleNavigate('prev')"
              @keydown.native.enter.prevent="selectOption"
              @keydown.native.esc.stop.prevent="visible = false"
              @keydown.native.tab="visible = false">
      <transition name="el-zoom-in-extend-right" slot="suffix">
        <el-icon name="loading" style="margin-right: 5px;" v-show="loading"></el-icon>
      </transition>
    </el-input>
    <transition name="el-zoom-in-top">
      <el-select-menu
          ref="popper"
          :style="{maxWidth: inputWidth + 'px',minHeight:'34p',}"
          append-to-body
          v-show="visible">
        <el-scrollbar tag="ul" wrap-class="el-select-dropdown__wrap" view-class="el-select-dropdown__list"
                      ref="scrollbar">
          <el-option class="el-select-input_item-wrap" v-for="item in displayList" v-bind="item"
                     @click.native.stop="handleOptionClick(item)"
                     :label="item.label" :value="item.value" :key="item.value">
            <div class="content">
              <span class="left">{{ item.label }}</span>
              <span class="right">{{ item.value }}</span>
            </div>
          </el-option>
        </el-scrollbar>
      </el-select-menu>
    </transition>
  </div>
</template>
<script>
import Element            from "element-ui";
import ElSelectMenuExtend from "@/components/elementExtend/ElSelectMenuExtend";
import Clickoutside       from "element-ui/src/utils/clickoutside";
import NavigationMixin    from "element-ui/packages/select/src/navigation-mixin";
import scrollIntoView     from "element-ui/src/utils/scroll-into-view";

export default {
  name: "ElSelect",
  mixins: [NavigationMixin],
  data() {
    return {
      visible: false,
      loading: false,
      inputWidth: 0,
      queryTimeout: 0,
      hoverIndex: -1,
      displayList: [],
      options: [],
      cachedOptions: [],
      optionsCount: 0,
      inputValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue(val) {
      this.$emit("change", val);
    },
    visible(visible) {
      if (visible) {
        this.resetInputWidth();
      }
    },
  },
  mounted() {
    this.resetInputWidth();
  },
  provide() {
    return {
      "select": this
    };
  },
  methods: {
    handleNavigate(direction) {
      if (this.isOnComposition) return;

      this.navigateOptions(direction);
    },
    handleOptionClick(option) {
      this.$emit("change", option.value);
      this.visible = false;
    },
    // 搜索
    async onQuery(filter) {
      let options = this.api;

      // 处理API行为
      if (typeof options === "function" && this.remote) {
        const getArray = (...args) => {
          for (let param of args) {
            if (Array.isArray(param)) return param;
          }

          return [];
        };
        let resp = await options(filter ?? "", this.apiParams);
        if (this.apiCallback) {
          resp = this.apiCallback(resp);
        }
        this.hoverIndex = -1;
        this.displayList = getArray(resp?.Items, resp?.data, resp);
      } else if (Array.isArray(options)) {
        if (filter) {
          options = options.filter(item => Object.keys(item).some(key => `${item[key]}`.includes(filter)));
        }
        this.displayList = options;
      }
    },
    resetInputWidth() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect?.()?.width;
      console.log("this.inputWidth", this.inputWidth);
    },
    async onHandleFocus() {
      if (this.value) {
        if (this.displayList.length <= 0) {
          await this.onQuery(this.value);
        }

        this.visible = this.displayList.length > 0;
      }
    },
    handleClose(ev) {
      this.visible = false;
    },
    onCheckOptions(query) {
      clearTimeout(this.queryTimeout);
      this.loading = true;

      console.log("query", query);
      this.queryTimeout = setTimeout(async () => {
        await this.onQuery(query);
        this.loading = false;

        this.visible = this.displayList.length > 0;
      }, 50);
    },
    onOptionDestroy(index) {
      if (index > -1) {
        this.optionsCount--;
        this.filteredOptionsCount--;
        this.options.splice(index, 1);
      }
    },
    scrollToOption(option) {
      const target = Array.isArray(option) && option[0] ? option[0].$el : option.$el;
      if (this.$refs.popper && target) {
        const menu = this.$refs.popper.$el.querySelector(".el-select-dropdown__wrap");
        scrollIntoView(menu, target);
      }
      this.$refs.scrollbar && this.$refs.scrollbar.handleScroll();
    },

    selectOption() {
      if (!this.visible) {
        this.toggleMenu();
      } else {
        if (this.options[this.hoverIndex]) {
          this.handleOptionClick(this.options[this.hoverIndex]);
        }
      }
    },
  },
  toggleMenu() {
    if (!this.selectDisabled) {
      if (this.menuVisibleOnFocus) {
        this.menuVisibleOnFocus = false;
      } else {
        this.visible = !this.visible;
      }
      if (this.visible) {
        (this.$refs.input || this.$refs.reference).focus();
      }
    }
  },
  computed: {
    InputProps() {
      return Object.keys(Element.Input.props).reduce((props, key) => {
        props[key] = this.$props[key];

        return props;
      }, {
        placeholder: this.placeholder,
      });
    },
  },
  props: {
    placeholder: String,
    value: String,
    disabled: Boolean,
    // 是否将options接口设置为即时调用
    remote: Boolean,
  },
  directives: {
    Clickoutside,
  },
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    ElSelectMenu: ElSelectMenuExtend,
  },
};
</script>

<style scoped lang="scss">
.el-zoom-in-extend-right-enter-active,
.el-zoom-in-extend-right-leave-active {
  transition: .2s linear;
}

.el-zoom-in-extend-right-enter,
.el-zoom-in-extend-right-leave-to {
  translate: 10px 0;
  opacity: 0;
}

.el-zoom-in-extend-right-enter-to,
.el-zoom-in-extend-right-leave {
  translate: 0 0;
  opacity: 1;
}

.el-select-input_item-wrap {
  white-space: pre-wrap;
  height: unset;
  min-height: 34px;
  line-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;

  &:not(.selected) {
    .content {
      .left {
        color: #333;
      }

      .right {
        color: #999;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    > span {
      flex-grow: 1;
      flex-shrink: 0;
      width: 0;
    }

    .left {
      padding-right: 5px;
      text-align: left;
    }

    .right {
      padding-left: 5px;
      text-align: right;
    }
  }
}
</style>

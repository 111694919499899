// noinspection JSUnresolvedFunction

import router     from "@/router";
import NProgress  from "nprogress";
import store      from "@/store";
import {getToken} from "@/store/modules/user";

NProgress.configure({showSpinner: false});

function refreshDocumentClass(to, from) {
  let convert = (name, suffix) => {
    name = name ?? "";
    name = `${name.replace(/([A-Z])/g, "-$1")}`.toLowerCase();

    if (name[0] === "-") {
      name = name.slice(1, name.length);
    }

    return name + suffix;
  };
  let toClassName = convert(to.name, "-html");
  let fromClassName = convert(from.name, "-html");

  const root = document.documentElement;
  const classList = root.className.split(" ")?.filter(x => Boolean(x) && x !== fromClassName) ?? [];


  classList.push(toClassName);
  root.className = classList.join(" ");
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  store.commit('lang/refreshDocumentTitle', store.getters.locale);

  if (!store.state.token) {
    const token = getToken();

    if (token?.Id) {
      store.commit("user/SET_TOKEN", token);
      const message = await store.dispatch("user/GET_INFO");

      if (message) {
        this.$message.error(message);
      }
    }
  }

  next();
});

router.afterEach((to, from) => {
  refreshDocumentClass(to, from);
  NProgress.done();
});

const TableSpecificationsEnum = {
  PreDefine_BookingRailwayRequest: "BookingRailwayRequestField",
  PreDefine_BookingSeaRequest: "BookingSeaRequestField",
  PreDefine_BookingAirRequest: "BookingAirRequestField",
  PreDefine_FreightRailwayTransport: "FreightRailwayTransport",
  PreDefine_BookingTruckTransport: "BookingTruckTransport",
  PreDefine_BookingMultiTrainTransport: "BookingMultiTrainTransport",
  PreDefine_FreightSeaTransport: "FreightSeaTransport",
  PreDefine_FreightAirTransport: "FreightAirTransport",
  PreDefine_BoxContainerInfo: "BoxContainerInfo",
  PreDefine_DiscountColumn: "DiscountColumn",
  PreDefine_WorkOrderTodoList:'WorkOrderTodoList',

  // 工作台-订单列表
  PreDefine_WorkbenchRailwayColumn: "WorkbenchRailwayColumn",
  PreDefine_WorkbenchTruckColumn: "WorkbenchTruckColumn",
  PreDefine_WorkbenchSeaColumn: "WorkbenchSeaColumn",
  PreDefine_WorkbenchAirportColumn: "WorkbenchAirportColumn",
  PreDefine_WorkbenchMultiTrainColumn: "WorkbenchMultiTrainColumn",

  // 订单  订单-工作单
  PreDefine_RailwayOrderList:'RailwayOrderList',
  PreDefine_TruckOrderList:'TruckOrderList',
  PreDefine_AirportOrderList:'AirportOrderList',
  PreDefine_SeaOrderList:'SeaOrderList',
  PreDefine_MultiTrainOrderList:'MultiTrainOrderList',
  PreDefine_RailwayOrderWorkOrderList:'RailwayOrderWorkOrderList',
  PreDefine_TruckOrderWorkOrderList:'TruckOrderWorkOrderList',
  PreDefine_AirportOrderWorkOrderList:'AirportOrderWorkOrderList',
  PreDefine_SeaOrderWorkOrderList:'SeaOrderWorkOrderList',
  PreDefine_MultiTrainOrderWorkOrderList:'MultiTrainOrderWorkOrderList',

  // 工作单
  PreDefine_RailwayWorkOrderList:'RailwayWorkOrderList',
  PreDefine_TruckWorkOrderList:'TruckWorkOrderList',
  PreDefine_AirportWorkOrderList:'AirportWorkOrderList',
  PreDefine_SeaWorkOrderList:'SeaWorkOrderList',
  PreDefine_MultiTrainWorkOrderList:'MultiTrainWorkOrderList',
  // 箱装信息
  PreDefine_RailwayWorkOrderDetailPackingList:'RailwayWorkOrderDetailPackingList',
  PreDefine_ShipWorkOrderDetailPackingList:'ShipWorkOrderDetailPackingList',
  PreDefine_DomesticTruckWorkOrderDetailPackingList:'DomesticTruckWorkOrderDetailPackingList',
  PreDefine_AbroadTruckWorkOrderDetailPackingList:'AbroadTruckWorkOrderDetailPackingList',
  PreDefine_MultiTrainWorkOrderDetailInfo:'MultiTrainWorkOrderDetailInfo',


  /*订单号*/      OrderNo: "BookingNo",
  /*订单号 无跳转*/      OrderNoNolink: "Nolink.BookingNo",
  /*订单号 弹窗*/  OrderNoPopover: "Order.BookingNo",
  /*委托号*/      EntrustNo: "ProxyNo",
  /*始发地*/      PlaceOfOrigin: "Booking.PortlDisplay",
  /*目的地*/      Destination: "Booking.PortdDisplay",
  /*装货港*/      SeaPlaceOfOrigin: "Sea.Portl",
  // /*装货港*/      MultiPlaceOfOrigin: " Multi.Placer",
  // /*目的站*/      MultiPlacdOfOrigin: " Multi.Placed",
  /*卸货港*/      SeaDestination: "Sea.Portd",
  /*箱型箱量*/     BoxTypeAndVolume: "ConStat",
  /*进出口*/       IOM: "IeId",
  /*用箱方式*/     BoxMode: "SocId",
  /*装箱方式*/     PackingMethod: "LclId",
  /*货物品名*/     Commodity: "AbbrName",
  /*货物品名(v2) */CargoName: "Freight.CargoName",
  /*预计发车日期*/  ETD: "Etsh",
  /*ETD*/         EngETD: "Eng.Etsh",
  /*ETA*/         EngETA: "Eng.Etad",
  /*ATD*/         EngATD: "Eng.Atd",
  /*ATA*/         EngATA: "Eng.Ata",
  /*船期*/         SeaEtsh: "Sea.Etsh",
  /*订单状态*/     OrderStatus: "StateText",
  /*状态*/     State: "State",
  /*申请时间*/     ApplicationDate: "CreateTime",
  /*运踪状态*/      BookingTrackStatus: "TrackText",

  /*工作号*/       JobNumber: "JobNoIn",
  /*工作号 跳转*/       JobNumberLink: "Link.JobNoIn",
  /*工作号 跳转*/       AirJobNumberLink: "AirLink.JobnoIn",
  /*箱号*/         ContainerNo: "ConNo",
  /*始发站*/        DepartureStation: "Freight.Portl",
  /*目的站*/        DestinationStation: "Freight.Portd",
  /*发车日期*/      DepartureDate: "Freight.Etsh",
  /*跟踪状态*/      TrackStatus: "Freight.Status",

  /*箱型*/         BoxType: "SizeType",
  /*封签号*/        SealNo: "SealNo",
  /*件数/箱*/       PiecesBox: "Ctns",
  /*件数/单位*/     PiecesUnit: "unit.Ctns",

  /*过磅件数*/       NumberWeightWidth: "width.RealRcp",
  /*过磅毛重*/       RealGsWeightWidth: "width.RealGsWeight",

  /*毛重/KGS*/     GrossWeight: "Weight",
  /*体积/CBM*/     Volume: "Meas",
  /*运单号*/        WaybillNo: "Blno",
  /*箱皮重/KGS*/    TareWeight: "ConWeight",
  /*加固重量/KGS*/   ReinforcementWeight: "FixWeight",
  /*VGM/KGS*/       VGM: "VGMWeight",
  
   /*车号*/        VehicleNo: "TruckNo",
  /*司机*/        Driver: "Driver",
  /*司机电话*/        DriverPhone: "DriverTel",
  /*品名描述*/        ProductDesc: "CargoName",
  /*拖车公司*/        CarTowCompany: "Trailer",
  /*装货时间*/        LoadingTime: "LoadDate",
  /*详细地址*/        Address: "LoadPlaceDoc",

  /*航司*/         AirManager: "Air.Manager",

  /*提单号*/       SeaWaybillNo: "Sea.Blno",
  /*主单号*/       AirWaybillNo: "MBlno",
  /*航期*/         FlyDate: "FlyDate",
  /*航司*/         Airways: "Airways",
  /*始发站*/       AirPortDepEng: "Air.AirPortDepEng",
  /*目的站*/       AirPortDesEng: "Air.AirPortDesEng",

  /*起运地*/       TruckPortl: "Truck.Portl",
  /*目的地*/       TruckPortd: "Truck.Portd",
  // /*起运地*/       TruckPortl: "Truck.PortlDisplay",
  // /*目的地*/       TruckPortd: "Truck.PortdDisplay",

  /*起运港*/       MultiPortl: "Multi.PlacerDisplay",
  /*目的站*/       MultiPortd: "Multi.PlacedDisplay",

  // /*起运地*/         Placer: "Railway.Placer",
  // /*目的地*/       Placed: "Railway.Placed",
  /*起运站*/         PlacerDisplay: "Railway.PlacerDisplay",
  /*目的站*/       PlacedDisplay: "Railway.PlacedDisplay",


  /*起运站*/         RailwayPortl: "Railway.Portl",
  /*目的站*/       RailwayPortd: "Railway.Portd",
  /*起运地*/         RailwayPlacer: "RailwayOrder.Placer",
  /*目的地*/       RailwayPlaced: "RailwayOrder.Placed",


  /*工作台 起运地*/       Portl: "Workbench.Portl",
  /*工作台 目的站*/       Portd: "Workbench.Portd",
  /*预计发车*/       Etsh: "Workbench.Etsh",
  /*预计到站*/       Etad: "Workbench.Etad",
  /*实际发车*/       Atd: "Workbench.Atd",
  /*实际到站*/       Ata: "Workbench.Ata",
  /*下单时间*/       OrderTime: "Order.CreateTime",
  /*完成时间*/       OrderDeliveryTime: "OrderDt",

  /*待办事项*/        TodoList: "TodoList",
  /*发送时间*/        SendTime: "SendDate",
  /*序号 宽度*/    SerialNoWidth: "width.Index",

  /*货物量*/    BoxVolume: "bench.ConStat",
  /*货物名称*/     CommodityName: "bench.AbbrName",

  /*工作号 加宽*/   AirJobNumberWidth: "Width.JobnoIn",
  /*工作号 加宽*/   AirJobNumberWidthLink: "Link.JobnoIn",

    /*国内车型(量)*/       DomesticVehicleVolume: "CatTypeIn",
  /*国外车型(量)*/       ForeignVehicleVolume: "CatTypeOut",

    /*订舱号*/      Sono: "Sono",
  /*备注*/        Remarks: "Remark",


};

export default TableSpecificationsEnum;

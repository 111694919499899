<template>
  <!--  <el-config-provider :locale="zhCn">-->
  <virtual-scrollbar>
    <router-view :class="{'fs-10':isRu}"/>
  </virtual-scrollbar>
</template>
<script>
import {v4 as guid}     from "uuid";
// import {useStore}         from "vuex";
// import {ElConfigProvider} from "element-plus";
// import zhCn from "element-plus/lib/locale/lang/zh-cn";

// const store = useStore();
// store.dispatch("INITIALIZE_LANGUAGES", "zh-CN");
import VirtualScrollbar from "@/components/VirualScrollbar";

export default {
  components: {VirtualScrollbar},
  created() {
    this.$store.dispatch("lang/INITIALIZE_LANGUAGES", window.localStorage.getItem("lang") || "zh-CN");

    if (!window.localStorage.getItem("BROWSER_IDENTIFY_UUID")) {
      window.localStorage.setItem("BROWSER_IDENTIFY_UUID", guid());
    }
  },
};
</script>

<style lang="scss">
@import "./styles/common";
</style>


export default {
  state: {
    LclOptions: [
      {
        label: 'TranspertGlobalKey.FCL',
        value: 'FCL'
      },
      {
        label: 'TranspertGlobalKey.LCL2',
        value: 'LCL'
      }
    ],
    TrafficOptions: [
      {
        label: 'TranspertGlobalKey.FullRoute',
        value: 'Q'
      },
      {
        label: 'TranspertGlobalKey.FirstLeg',
        value: 'T'
      },
      {
        label: 'TranspertGlobalKey.LastLeg',
        value: 'H'
      }
    ],
    TruckLclOptions: [
      {
        label: 'TranspertGlobalKey.FTL',
        value: 'FCL'
      },
      {
        label: 'TranspertGlobalKey.LTL',
        value: 'LCL'
      }
    ],
    AirType: [
      {
        label: 'TranspertGlobalKey.MasterBillOfLading',
        value: 'M'
      },
      {
        label: 'TranspertGlobalKey.HouseBillOfLading',
        value: 'P'
      }
    ],
    UseConType: [
      {
        label: 'TranspertGlobalKey.COC',
        value: 'C'
      },
      {
        label: 'TranspertGlobalKey.SOC',
        value: 'S'
      }
    ],
    CargoKindList: [
      {
        label: 'TranspertGlobalKey.CommonGoods',
        value: '普货'
      },
      {
        label: 'TranspertGlobalKey.DangerousGoods',
        value: '危险品'
      }
    ],
    ConMeasLocalData: {
      '20GP': {
        // 长
        long: 5.898,
        // 宽
        width: 2.352,
        // 高
        height: 2.38
      },
      '40GP': {
        // 长
        long: 12.032,
        // 宽
        width: 2.352,
        // 高
        height: 2.38
      },
      '40HQ': {
        // 长
        long: 12.032,
        // 宽
        width: 2.352,
        // 高
        height: 2.69
      },
      '40FR': {
        // 长
        long: 11.762,
        // 宽
        width: 2.24,
        // 高
        height: 2.03
      },
      '40OT': {
        // 长
        long: 12.034,
        // 宽
        width: 2.352,
        // 高
        height: 2.33
      },
      '40RF': {
        // 长
        long: 13.233,
        // 宽
        width: 2.437,
        // 高
        height: 2.57
      },
      '45HQ': {
        // 长
        long: 13.556,
        // 宽
        width: 2.352,
        // 高
        height: 2.69
      }
    }
  }
}

<script>
import Element            from "element-ui";
import ElInputExtendEx    from "@/components/elementExtend/ElInputExtendEx";
import ElSelectMenuExtend from "@/components/elementExtend/ElSelectMenuExtend";

// 真· ElSelect扩展
export default {
  name: "ElSelectExtendEx",
  extends: Element.Select,
  components: {
    ElInput: ElInputExtendEx,
    ElSelectMenu: ElSelectMenuExtend,
  },
  computed: {
    selectDisabled() {
      return this.disabled;
    },
  },
  methods: {
    toggleMenu() {
      if (!this.selectDisabled) {
        this.visible = !this.visible;

        if (this.visible) {
          (this.$refs.input || this.$refs.reference).focus();
        }
      }

      if (this.visible) {
        this.resetInputWidth();
      }
    },

    async handleOptionSelect(option, byClick) {
      if (this.value === option.value) {
        this.$super(Element.Select).handleOptionSelect(option, byClick);
        return;
      }
      const beforeOptionsClick = this.$listeners["beforeOptionsClick"] || this.$listeners["before-options-click"];
      if (beforeOptionsClick) {
        if (await beforeOptionsClick(option, () => {
          this.isSilentBlur = byClick;
          this.setSoftFocus();
          this.visible = false;
        }) === false) return;
      }
      this.$super(Element.Select).handleOptionSelect(option, byClick);
    }
  },
  props: {
    disabled: Boolean,
    element: [Object, Function],
    blurInput: Boolean,
  },
};
</script>

/**
 * 用来写一些全局公共方法
 */
import {v4 as uuidv4} from "uuid";
import Vue            from "vue";

Vue.prototype.$guid = () => uuidv4();
Vue.prototype.isNullOrUndefined = function (value) {
  return value === null || value === undefined;
};
window.isNullOrUndefined = function (value) {
  return value === null || value === undefined;
};
window.$CreateGuid = () => uuidv4();
window.JSONExtend = {
  tryParse(str, def) {
    try {
      return JSON.parse(str) ?? def;
    } catch (e) {
      return def;
    }
  },
};
window.flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = window.flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

window.validatorExtend = {
  Email(str) {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(str);
  },
  Chinese(str){
    return /[\u4e00-\u9fa5]/ig.test(str);
  }
}

Number.prototype.toTrimNum = function (length = 4) {
  const value = this.valueOf();
  let list = `${value}`.split(".");
  list[1] = list[1] || "";

  if (list[1].length < length) {
    for (let i = list[1].length; i < length; i++)
      list[1] += "0";
  }

  return list.join(".");
};
window.delay = function (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

Object.defineProperty(Object.prototype, "keyEntries", {
  value(callback = null) {
    const keys = Object.keys(this.valueOf());
    if (typeof callback === "function") {
      keys.forEach(callback);
    }
    return keys;
  },
  enumerable: false,
});

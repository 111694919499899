<template>
  <div ref="Swiper" class="swiper">
    <div ref="Wrapper" class="swiper-wrapper">
      <slot/>
    </div>
    <slot v-if="pagination !== null && pagination !== undefined && ($slots.pagination || $scopedSlots.pagination)"
          name="pagination"/>
    <div v-else-if="pagination !== null && pagination !== undefined" class="pagination">
      <a href="javascript:void(0);" @click="slideTo(_index)" v-for="(i,_index) in SLOT_DEFAULT_LENGTH" class="point"
         :class="{'active':REAL_INDEX === _index,[PAGINATION_CLASS]:true,}"/>
    </div>
  </div>
</template>

<script>
import "swiper/css";
import "swiper/css/effect-fade";
import Swiper, {EffectFade, Autoplay} from "swiper";
// import RequestAnimation from "@/mixins/requestAnimation";

export default {
  name: "Swiper",
  props: {
    modules: String,
    index: Number,
    spaceBetween: [Number, String],
    pagination: String,
    effect: String,
    disabled: Boolean,
    speed: Number,
    autoPlay: Number,
    slidesPerView: Number,
  },
  computed: {
    PAGINATION_CLASS() {
      return this.pagination || "circle";
    },
    REAL_INDEX() {
      return this.swiper?.realIndex || 0;
    },
    SLOT_DEFAULT_LENGTH() {
      const length = this.$slots.default?.length || this.$scopedSlots.default?.length || 0;
      return new Array(length).map((i, index) => index);
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  watch: {
    index(result) {
      this.slideTo(result);
    },
  },
  mounted() {
    const options = {
      spaceBetween: +this.spaceBetween || 0,
      slidesPerView: this.slidesPerView || 1,
      modules: [],
      initialSlide: this.index,
      allowTouchMove: !this.disabled,
    };
    if (this.autoPlay > 0) {
      options.modules.push(Autoplay);
      options.autoplay = {
        delay: this.autoPlay,
      };
    }

    if (this.effect) {
      if (this.effect === "fade") {
        options.effect = this.effect;
        options.modules.push(EffectFade);
      }
    }

    if (this.speed) {
      options.speed = this.speed;
    }

    this.swiper = new Swiper(this.$refs.Swiper, options);
    this.onEvents();
    // this.setAction(this.onCheck)
  },
  // mixins: [RequestAnimation],
  methods: {
    /*onCheck(){
        // console.log()
    },*/
    slideTo(result) {
      this.swiper.slideTo(result);
    },
    onEvents() {
      const _s = this.swiper;
      /*_s.on("setTranslate", (...rest) => {
        // this.$emit("sliderMove", ...rest);
        // console.log("setTranslate:", rest[0].translate);
      }).on("slideChangeTransitionEnd", (...rest) => {
        console.log("slideChangeTransitionEnd", ...rest);
      }).on("slideResetTransitionEnd", (...rest) => {
        console.log("slideResetTransitionEnd", ...rest);
      });*/
      _s.on("slideChange", (swiper, ...rest) => {
        this.$emit("update:index", swiper.realIndex);
        this.$emit("slideChange", swiper, ...rest);
      });
    },
  },
  beforeDestroy() {
    this.swiper.destroy();
  },
};
</script>

<style scoped lang="scss">
.swiper {
  position: relative;
  width: 100%;
}

.swiper-wrapper {
  align-items: flex-end;
}

.pagination {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  > .point {
    width: 10px;
    height: 10px;
    border-radius: 99px;
    background: #fff;
    margin: 0 15px;
    position: relative;
    z-index: 1;
    opacity: .8;
    transition: opacity .2s linear;

    &.active {
      opacity: 1;
    }

    &.rectangle {
      width: 40px;
      height: 4px;
      margin: 0 8px;
      border-radius: 0;
    }
  }
}
</style>
<template>
  <div class="header-layout">
    <div class="media-container-container">
      <app-header
        ref="APP_HEADER"
        :fixed="true"
        :isScroll="isScroll"
        :space="$route.meta['space'] !== false"
        :absolute="false && !!$route.meta['black']"
        :pageType="$route.meta['workbench']"
      />
      <!-- <app-header ref="APP_HEADER" :fixed="true" :space="$route.meta['space'] !== false" :absolute="!fixed && !!$route.meta['black']"/> -->
    </div>
    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  name: 'HeaderLayout',
  components: { AppHeader },
  data() {
    return {
      fixed: false,
      isScroll: false
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const y = window.pageYOffset || window.scrollY

      this.fixed = y > 20
      this.isScroll = y > 40
    }
  }
}
</script>

<style scoped></style>

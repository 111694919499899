<script>
import Element from "element-ui";

export default {
  name: "ElCascaderExtend",
  extends: Element.Cascader,
  watch:{
    '$i18n.locale'(){
      this.computePresentText();
    }
  },
  methods: {
    computePresentText() {
      const {checkedValue, config} = this;
      if (!this.isEmptyValue(checkedValue)) {
        const node = this.panel.getNodeByValue(checkedValue);
        if (node && (config.checkStrictly || node.isLeaf)) {
          this.presentText = node.getText(this.showAllLevels, this.separator).split(this.separator).map(x => this.$t(x)).join(this.separator);
          return;
        }
      }
      this.presentText = null;
    },
  }
};
</script>

<style scoped>

</style>

// 处理来自接口的国际化数据
export function ProgressLocaleInitialize(responseData, options = {}) {
  try {
    // 检测是否存在Display型字段
    let responseStr = JSON.stringify(responseData);
    if (responseStr.includes("Display")) {
      // 提取Display字段

      const map = new Map();
      /{[^{]+"[a-zA-Z]+Display":[^}]+}/.exec(responseStr).map(str => {
        try {
          const i18n = window.$$Application.$i18n;
          const model = JSON.parse(str);
          const displayProps = Object.keys(model).filter(prop => prop.endsWith("Display"));
          const interfaceInfo = {
            "zh-CN": {},
            [i18n.locale]: {},
          };

          displayProps.forEach(displayProp => {
            const originProp = displayProp.split("Display")[0];
            // 存在无值的情况则略过
            if (!model[originProp] || !model[displayProp]) return;

            interfaceInfo["zh-CN"][options.url + originProp] = model[originProp];
            interfaceInfo[i18n.locale][options.url + originProp] = model[displayProp];
          });

          i18n.mergeLocaleMessage("zh-CN", {
            InterfaceInfo: interfaceInfo["zh-CN"],
          });
          i18n.mergeLocaleMessage(i18n.locale, {
            InterfaceInfo: interfaceInfo[i18n.locale],
          });
        } catch {

        }
      });
    }
  } catch {
    // 抛异常则无视
  }
}

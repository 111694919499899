import Vue from "vue";

const cssRules = {};
const style = document.createElement("style");
window.addEventListener("load", () => {
  document.head.appendChild(style);
});

Vue.directive("flex", (el, binding, vnode, oldVnode) => {
  const addClass = className => {
    if (!el.className.split(" ").includes(className)) {
      el.className += `${el.className ? " " : ""}${className}`;
    }
  };

  addClass("flex");
  const {value, modifiers} = binding;
  const {center, middle, wrap, basis} = modifiers;
  if (center) {
    addClass("center");
  }

  if (basis) {
    let prefix = "-fixed";
    if (value.indexOf("%") > -1) {
      prefix = "-percent";
    }
    // const isOmsFormItem = vnode.componentOptions.tag === "oms-form-item";
    const key = `__expression_flex-child${prefix}-basis-${parseInt(value)}`;
    if (!cssRules[key]) {
      style.appendChild(document.createTextNode(`.${key} > *{
        flex-basis: ${value};
      }`));
    }

    addClass(key);
  }

  if (middle) {
    addClass("middle");
  }

  if (wrap) {
    addClass("wrap");
  }
});

Vue.directive("flex-property", {
  bind: async (el, binding, vnode, oldVnode) => {
    const addClass = className => {
      // if (!el.className.split(" ").includes(className)) {
      //   el.className += `${el.className ? " " : ""}${className}`;
      // }
      el.classList.add(className);
    };
    await vnode.componentInstance.$nextTick();

    const isOmsFormItem = vnode.componentOptions?.tag === "oms-form-item";

    const {value, modifiers} = binding;
    const {fill, shrink, basis} = modifiers;
    if (fill) {
      addClass("fill");
    }

    if (shrink) {
      addClass("shrink");
      addClass("w-0");
    }

    if (basis) {
      let prefix = "-fixed";
      if (value.indexOf("%") > -1) {
        prefix = "-percent";
      }
      const key = `__expression_flex${prefix}-basis-${parseInt(value)}`;

      if (!cssRules[key]) {
        cssRules[key] = {
          flexBasic: value,
        };

        style.appendChild(document.createTextNode(`.${key}{
        flex-basis: ${value};
      }`));
      }

      addClass(key);
    }
  },
  componentUpdated: async (el, binding, vnode, oldVnode) => {
    const addClass = className => {
      // if (!el.className.split(" ").includes(className)) {
      //   el.className += `${el.className ? " " : ""}${className}`;
      // }
      el.classList.add(className);
    };
    await vnode.componentInstance.$nextTick();

    const isOmsFormItem = vnode.componentOptions?.tag === "oms-form-item";

    const {value, modifiers} = binding;
    const {fill, shrink, basis} = modifiers;
    if (fill) {
      addClass("fill");
    }

    if (shrink) {
      addClass("shrink");
      addClass("w-0");
    }

    if (basis) {
      let prefix = "-fixed";
      if (value.indexOf("%") > -1) {
        prefix = "-percent";
      }
      const key = `__expression_flex${prefix}-basis-${parseInt(value)}`;

      if (!cssRules[key]) {
        cssRules[key] = {
          flexBasic: value,
        };

        style.appendChild(document.createTextNode(`.${key}{
        flex-basis: ${value};
      }`));
      }

      addClass(key);
    }
  }
});

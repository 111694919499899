import Vue from 'vue'
import moment from 'moment-timezone'

Vue.filter('displayDate', (value) => {
  if (!value) return value
  const mom = moment(value)
  if (mom.isValid()) {
    return moment(value).format('YYYY-MM-DD')
  }

  return value
})
Vue.filter('valueFilter', (val) => {
  if (val !== 0) {
    return val === '' ? '--' : val ?? '--'
  } else {
    return val
  }
})

// 金额格式化，两位小数加千分位,flag表示是否显示金额为0的情况
export function parseMoney(value, flag = false, def = '') {
  if (!value && !flag) {
    return def
  } else if (!value && flag) {
    return '0.00'
  }
  if (isNaN(parseInt(value))) {
    return def || value
  }
  // // console.log(parseFloat(value))
  var money = parseFloat(value).toFixed(2)
  // // console.log(money)
  var intPart = parseInt(money) // 获取整数部分
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  var floatPart = '.00' // 预定义小数部分
  var value2Array = String(money).split('.')
  var result = ''
  // =2表示数据有小数位
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString() // 拿到小数部分

    if (floatPart.length === 1) {
      // 补0,实际上用不着
      result = intPartFormat + '.' + floatPart + '0'
    } else {
      result = intPartFormat + '.' + floatPart
    }
  } else {
    result = intPartFormat + floatPart
  }
  if (String(result).indexOf('-') < 0 && Number(value) < 0) {
    result = '-' + result
  }
  return result
}

Vue.filter('parseMoney', parseMoney)

<template>
  <div class="el-form-item el-form-item__extend" :data-key-required="'' + required" :data-key-isRequired="isRequired + ''"
       :data-key-getRules="JSON.stringify(getRules())"
       :class="[{
      'el-form-item--feedback': elForm && elForm.statusIcon,
      'is-error': validateState === 'error',
      'is-validating': validateState === 'validating',
      'is-success': validateState === 'success',
      'is-required': isRequired || required !== false,
      'required-space': requiredSpace,
      'is-no-asterisk': elForm && elForm.hideRequiredAsterisk,
      'el-form-item--inline': inline,
    },
    sizeClass ? 'el-form-item--' + sizeClass : ''
  ]">
    <label-wrap
        :is-auto-width="labelStyle && labelStyle.width === 'auto'"
        :update-all="form.labelWidth === 'auto'">
      <label :for="labelFor" class="el-form-item__label" :class="[labelClass,labelCommonClass]" :style="labelStyle"
             v-if="label || $slots.label">
        <slot name="label">
          {{ label + form.labelSuffix }}
        </slot>
        <slot name="label-suffix"></slot>
      </label>
    </label-wrap>
    <div class="el-form-item__content" :class="contentClass" :style="contentStyle">
      <slot></slot>
      <transition name="el-zoom-in-top">
        <slot
            v-if="validateState === 'error' && showMessage && form.showMessage"
            name="error"
            :error="onChangeValidateMessage(validateMessage)">
          <div
              class="el-form-item__error"
              :class="{
              'el-form-item__error--inline': typeof inlineMessage === 'boolean'
                ? inlineMessage
                : (elForm && elForm.inlineMessage || false)
            }"
          >
            {{ onChangeValidateMessage(validateMessage) }}
          </div>
        </slot>
      </transition>
    </div>
  </div>
</template>

<script lang="jsx">
import {FormItem} from "element-ui";
import {getPropByPath} from 'element-ui/src/utils/util';

export default {
  name: "ElFormItemExtend",
  extends: FormItem,
  computed: {
    labelCommonClass() {
      let list = [];

      if (this.position) {
        list.push(`el-form-item__label-extend--${this.position}`);
      }
      if (this.inline) {
        list.push(`el-form-item__label-extend--inline`);
      }

      return list;
    },
    labelStyle() {
      const ret = {};
      if (this.form.labelPosition === "top") return ret;
      if (this.form.inline || this.inline) return ret;
      const labelWidth = this.labelWidth || this.form.labelWidth;
      if (labelWidth) {
        ret.width = labelWidth;
      }
      return ret;
    },
  },
  methods: {
    getRules() {
      let formRules = this.form.rules;
      const selfRules = this.rules;
      const requiredRule = this.required !== undefined ? { validator(rule,value,callback){
        return callback();
        } } : [];

      const prop = getPropByPath(formRules, this.prop || '');
      formRules = formRules ? (prop.o[this.prop || ''] || prop.v) : [];

      // return [].concat(selfRules || formRules || []);
      return [].concat(selfRules || formRules || []).concat(requiredRule);
    },
    onChangeValidateMessage(validateMessage) {
      if (this.autoTranslate) {
        let key = this.$fKey(validateMessage, this.ruleTranslateKey);
        // this.localValidateMessageKey = key;

        return this.$t(key);
      }

      return validateMessage;
    },
  },
  props: {
    inline: Boolean,
    position: String,
    autoTranslate: Boolean,
    labelClass: [String, Object, Array],
    contentClass: [String, Object, Array],
    required: [Object, String, Boolean],
    requiredSpace: Boolean,
    ruleTranslateKey: String,
  },
};
</script>

<style lang="scss">

.el-form-item__extend.is-required:not(.is-no-asterisk) > .el-form-item__label {
  padding-left: 15px;

  &:before {
    position: absolute;
    margin-left: -15px;
  }
}

.el-form-item.required-space:not(.is-no-asterisk) > .el-form-item__label {
  padding-left: 15px;

  &:before {
    /* 用同一字符空出位置 */
    position: absolute;
    margin-left: -15px;
  }
}

.oms-form-extend .el-form-item__label.el-form-item__label-extend--inline {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: calc(14px - var(--default_font-size_offset));
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  @for $index from 1 through 15 {
    &.pl-#{$index} {
      padding-left: #{$index}px;
    }
  }
}

.oms-form-extend .el-form-item--inline {
  display: flex;

  > .el-form-item__label {
    flex-shrink: 0;
  }

  > .el-form-item__content {
    flex-grow: 1;
  }
}

/*.oms-form-extend .el-form-item__label.el-form-item__label-extend--inline + .el-form-item__content {
  overflow: hidden;
}*/

//.el-form-item__label-extend--
</style>

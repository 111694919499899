<template>
  <header
      :class="[
      $i18n.locale,
      { fixed: fixed, absolute: absolute, space: space, isScroll: isScroll, 'work-page': IS_WORKPAGE }
    ]"
  >
    <div class="header-space"></div>
    <div class="header-container">
      <div class="header" :class="{ isScroll: isScroll }">
        <div class="container flex center between relative"
             :class="{
                'pl-[120px]': IS_WORKPAGE,
             }"
             :style="{
          'max-width': IS_WORKPAGE ? '1680px' : '1200px',
          '--logo-size': IS_WORKPAGE ? '280px' : '92px',
        }">
          <!--      <div class="left flex center">-->
          <app-link name="Home" v-if="!IS_WORKPAGE">
            <logo class="shrink-0" :black="true"/>
          </app-link>
          <span v-else class="work-page-title">{{ $t("国际物流全链路产品及服务平台") }}</span>
          <!--      </div>-->
          <!-- todo:暂定在xs宽度下隐藏，待解决 -->
          <ul ref="MENU_UI" class="menu-ul flex center shrink-0 hidden-xs-only">
            <!-- 首页 -->
            <li class="menu-li"
                @mouseover="hoverState.home = true"
                @mouseleave="hoverState.home = false"
                :class="[
                hoverState.home || (NOT_ALLOW_HOVER && $route.name === 'Home') ? '' : 'text-fff-opacity',
                { on: hoverState.home || (NOT_ALLOW_HOVER && $route.name === 'Home') }
              ]">
              <app-link fill name="Home">
                <span>{{ $t("TranspertGlobalKey.Home") }}</span>
              </app-link>
            </li>
            <!-- 产品服务 -->
            <li class="menu-li"
                :class="[
                hoverState.productService || (NOT_ALLOW_HOVER && BUSINESS_LI_HOVER) ? '' : 'text-fff-opacity',
                { on: hoverState.productService || (NOT_ALLOW_HOVER && PRODUCT_SERVICE_LI_HOVER) }
              ]">
              <el-popover
                  v-model="hoverState.productService"
                  :width="POPOVER_STYLE"
                  transition="app-header-transition"
                  popper-class="header_popper-style business"
                  placement="bottom"
                  trigger="hover"
              >
                <div slot="reference" class="app-link">
                  <app-link>
                    <span>{{ $t("TranspertGlobalKey.productService") }}</span>
                  </app-link>
                </div>
                <div class="display-menu-list flex center middle">
                  <el-row class="header-menu">
                    <div v-for="(item, index) in SERVICE_MENU_LIST">
                      <app-link
                          class="header-menu-item"
                          :class="{ 'now-page': $route.params.type === item.params.type && $route.name === 'ReOrder' }"
                          @click.native="toServiceLink(item)"
                          :key="item.text"
                          @mouseenter.native="hoverIndex = index"
                          @mouseleave.native="hoverIndex = -1"
                          fill
                      >
                        <img
                            :style="{ width: item.width, height: item.height }"
                            :src="
                            MENU_IMG(
                              item.src,
                              hoverIndex === index ||
                                ($route.params.type === item.params.type && $route.name === 'ReOrder')
                            )
                          "
                            alt=""
                        />
                        <span>{{ $t_reserve(item.text) }}</span>
                      </app-link>
                    </div>
                  </el-row>
                </div>
              </el-popover>
            </li>
            <!-- 查询工具 -->
            <li
                v-if="false"
                class="menu-li"
                @mouseover="hoverState.searchTool = true"
                @mouseleave="hoverState.searchTool = false"
                :class="[
                hoverState.searchTool || (NOT_ALLOW_HOVER && SEARCH_TOOL_HOVER) ? '' : 'text-fff-opacity',
                { on: hoverState.searchTool || (NOT_ALLOW_HOVER && SEARCH_TOOL_HOVER) }
              ]"
            >
              <el-popover
                  v-model="hoverState.searchTool"
                  :width="POPOVER_STYLE"
                  transition="app-header-transition"
                  popper-class="header_popper-style business"
                  placement="bottom"
                  trigger="hover"
              >
                <div slot="reference" class="app-link">
                  <app-link fill>
                    <span>{{ $t("查询工具") }}</span>
                  </app-link>
                </div>
                <div class="display-menu-list flex center middle">
                  <el-row class="header-menu">
                    <app-link
                        class="header-menu-item"
                        v-for="(item, index) in SEARCH_MENU_LIST"
                        :class="{ 'now-page': $route.params.searchType === item.params.searchType }"
                        :name="item.name"
                        :params="item.params"
                        :key="item.text"
                        @mouseenter.native="hoverIndex = index"
                        @mouseleave.native="hoverIndex = -1"
                        fill
                    >
                      <img
                          :style="{ width: item.width, height: item.height }"
                          :src="
                          MENU_IMG(
                            item.src,
                            hoverIndex === index || $route.params.searchType === item.params.searchType
                          )
                        "
                          alt=""
                      />
                      <span>{{ $t_reserve(item.text) }}</span>
                    </app-link>
                  </el-row>
                </div>
              </el-popover>
            </li>
            <!-- 物流资讯 -->
            <li
                class="menu-li"
                @mouseover="hoverState.business = true"
                @mouseleave="hoverState.business = false"
                :class="[
                hoverState.business || (NOT_ALLOW_HOVER && $route.name === 'Tool') ? '' : 'text-fff-opacity',
                { on: hoverState.business || (NOT_ALLOW_HOVER && $route.name === 'Tool') }
              ]"
            >
              <a href="http://info.transpert.cn/" target="_blank">
                <span>{{ $t_reserve("物流资讯") }}</span>
              </a>
            </li>

            <!-- 工作台 -->
            <li
                class="menu-li"
                @mouseover="hoverState.workSpace = true"
                @mouseleave="hoverState.workSpace = false"
                :class="[
                hoverState.workSpace || (NOT_ALLOW_HOVER && WORK_SPACE_LI_HOVER) ? '' : 'text-fff-opacity',
                { on: hoverState.workSpace || (NOT_ALLOW_HOVER && WORK_SPACE_LI_HOVER) }
              ]"
            >
              <app-link name="Workbench" fill>
                <span>{{ $t("TranspertGlobalKey.workbench") }}</span>
              </app-link>
            </li>
            <!-- 联系我们 -->
            <li
                class="menu-li"
                @mouseover="hoverState.controlOrder = true"
                @mouseleave="hoverState.controlOrder = false"
                :class="[hoverState.controlOrder ? '' : 'text-fff-opacity', { on: hoverState.controlOrder }]"
            >
              <el-popover
                  v-model="hoverState.controlOrder"
                  :width="POPOVER_STYLE"
                  transition="app-header-transition"
                  popper-class="app-header_popper-style"
                  placement="bottom"
                  trigger="hover"
              >
                <div slot="reference" class="app-link">
                  <app-link>
                    <span>{{ $t_reserve("联系我们") }}</span>
                  </app-link>
                </div>
                <div class="display-menu-list flex center">
                  <el-row class="contact-us">
                    <div class="line-item">
                      <div>{{ $t_reserve("售前咨询") }}</div>
                      <div class="line-item-content">
                        <img src="@/assets/images/header-phone-icon@2x.png" alt=""/><span>{{
                          "(0755) 8305-6550"
                        }}</span>
                      </div>
                    </div>
                    <div class="line-item">
                      <div>{{ $t_reserve("售后咨询") }}</div>
                      <div class="line-item-content">
                        <img src="@/assets/images/header-phone-icon@2x.png" alt=""/>
                        <span>{{ "19129935683" }}</span>
                      </div>
                    </div>
                    <div class="line-item">
                      <div>{{ $t_reserve("体验反馈") }}</div>
                      <div class="line-item-content">
                        <img src="@/assets/images/header-line-icon@2x.png" alt=""/>
                        <span>{{ $t_reserve("页面反馈") }}</span>
                      </div>
                    </div>
                  </el-row>
                </div>
              </el-popover>
            </li>
          </ul>
          <div class="header-right-wrap">
            <div class="search-icon-wrap" @click="isSearch = true">
              <img src="@/assets/images/header-search@2x.png" class="search-icon" alt=""/>
              <span>{{ $t("Search") }}</span>
            </div>

            <div class="row-ul">
              <el-dropdown @command="handleCommand">
                <div class="header-language-wrap">
                  <img src="@/assets/images/header-language@2x.png" alt="" class="language-icon"/>
                  <span class="el-dropdown-link" :class="['text-color']">
                    {{ $t("TranspertGlobalKey.switchLanguage") }}
                  </span>
                </div>

                <el-dropdown-menu slot="dropdown" class="dropdown-menu lang-menu">
                  <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
                  <el-dropdown-item command="en">English</el-dropdown-item>
                  <el-dropdown-item command="zh-TW">繁體</el-dropdown-item>
                  <el-dropdown-item command="ru">Русский</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div style="transform: translateX(0px)" class="login-wrap row-ul">
              <a
                  href="javascript:void(0)"
                  @click="onClickToAccountContainer('loginByPhone')"
                  class="login-container flex center shrink-0 nowrap"
                  v-if="!isLogin"
              >
                <img src="@/assets/images/header-user@2x.png" class="icon" alt=""/>
                <span class="fs-14 fc-fff noLogin-text"
                >{{ $t("TranspertGlobalKey.Login") }}/{{ $t("TranspertGlobalKey.register") }}</span
                >
              </a>
              <a href="javascript:void(0);" v-else class="login-container flex column center middle">
                <el-popover trigger="hover" placement="bottom-end" popper-class="app-header_popper-style avatar">
                  <div class="user-info-wrap">
                    <div class="saler-info">
                      <p>
                        {{ `${$t_reserve("专属销售")}: ` }}
                        <span style="color: #333">{{ user.SalerEng || user.Saler | valueFilter }}</span>
                      </p>
                      <p>
                        {{ `${$t_reserve("联系电话")}: ` }}
                        <span style="color: #1b70d8">{{ user.SalerPhone || "--" }}</span>
                      </p>
                    </div>
                    <div class="control-list">
                      <app-link
                          class="set-link oms-hover"
                          v-for="(item, index) in USER_SET_LIST"
                          @click.native="setCenterType(item.centerType)"
                          :name="item.name"
                          :key="index"
                      >
                        <img
                            class="userSet-icon"
                            :style="{ width: item.width, height: item.height }"
                            :src="item.src"
                            alt=""
                        />
                        <span>{{ $t_reserve(item.text) }}</span>
                        <img style="width: 10px" src="@/assets/images/header-user-arrow@2x.png" alt=""/>
                        <!-- <app-link name="UserInformation" class="user-set oms-hover">
                          {{ $t('TranspertGlobalKey.AccountSettings') }}
                        </app-link> -->
                      </app-link>
                    </div>
                    <a @click="onLogout" class="log-out">{{ $t("TranspertGlobalKey.LogOut") }}</a>
                  </div>
                  <div slot="reference" class="login-wrap flex center">
                    <!-- <div slot="reference" class="flex center column"> -->
                    <!-- <el-avatar
                    v-loading="logoutLoading"
                    element-loading-spinner="el-icon-loading"
                    :size="35"
                    :src="user.Avatar"
                  /> -->
                    <img src="@/assets/images/header-user@2x.png" class="icon" alt=""/>
                    <p class="ml-10 lh-20 name-text">
                      <!-- <span v-if="accessState.NOT_ACCESS" class="fc-333">
                        {{ NICK_NAME ? NICK_NAME + ',' : $t('TranspertGlobalKey.account') }}
                        <app-link v-if="accessState.IS_CERTIFICATION" name="UserInformation">{{
                          $t('TranspertGlobalKey.UnderCertification')
                        }}</app-link>
                        <app-link v-else-if="accessState.CERTIFICATION_FAIL" name="UserInformation">{{
                          $t('TranspertGlobalKey.FailedCertification')
                        }}</app-link>
                        <app-link v-else name="UserInformation">{{ $t('TranspertGlobalKey.NotCertified') }}</app-link>
                      </span>
                      <span class="fc-fff" v-else>{{
                        NICK_NAME ||
                        (accessState.SUCCESS
                          ? $t('TranspertGlobalKey.Certified')
                          : $t('TranspertGlobalKey.NotCertified'))
                      }}</span> -->
                      {{ NICK_NAME ? NICK_NAME + "," : $t("TranspertGlobalKey.account") }}
                      <app-link v-if="accessState.IS_CERTIFICATION" name="UserInformation">{{
                          $t("TranspertGlobalKey.UnderCertification")
                        }}
                      </app-link>
                      <app-link v-else-if="accessState.CERTIFICATION_FAIL" name="UserInformation">{{
                          $t("TranspertGlobalKey.FailedCertification")
                        }}
                      </app-link>
                      <span class="fc-fff" v-else>{{
                          accessState.SUCCESS ? $t("TranspertGlobalKey.Certified") : $t("TranspertGlobalKey.NotCertified")
                        }}</span>
                      <!-- <a class="fc-82a5e0">去认证</a>-->
                    </p>
                  </div>
                </el-popover>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="header-search-container" :class="{ isScroll: isScroll, isSearch: isSearch }">
        <div class="header-search">
          <el-input
              class="header-search-input"
              :placeholder="$t_reserve('本周热门线路搜索')"
              prefix-icon="el-icon-search"
              v-model="searchText"
          >
          </el-input>
          <div class="header-search-button" @click="dialogVisible = true">{{ $t_reserve("搜索") }}</div>
        </div>
        <img class="search-close-icon" @click="isSearch = false" src="@/assets/images/close-icon@2x.png" alt="close"/>
      </div>
    </div>
    <!-- 登录/注册窗口 -->
    <oms-dialog
        :clear-effect="accountContainerType === 'loginByPhone'"
        :width="900"
        :visible.sync="accountVisible"
        :close-on-click-modal="true"
        :title="ACCOUNT_TITLE"
    >
      <div class="account-by-phone-container" v-if="accountContainerType === 'loginByPhone'">
        <!-- <button @click="accountVisible = false" type="button" aria-label="Close" class="el-dialog__headerbtn">
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button> -->
        <img src="@/assets/images/header-login-img@2x.png" alt=""/>
        <el-tabs v-model="loginTab" class="login-tabs" :class="[$i18n.locale == 'ru' ? 'tab-style-none-ru' : '']">
          <el-tab-pane name="account" :label="$t_reserve('账户登录')">
            <oms-row
                ref="ACCOUNT_ROW"
                class="mt-20"
                :model="HeaderInfo.MobileLogin"
                :rules="INFO_RULES.ACCOUNT_LOGIN"
                form
                form-item-margin="0 0 20px 0"
            >
              <oms-form-item>
                <el-input
                    v-model="HeaderInfo.MobileLogin.phone"
                    class="w-p-100 login-input"
                    :placeholder="$t('TranspertGlobalKey.PleaseEnterTheAccountNumber')"
                />
              </oms-form-item>
              <oms-form-item>
                <el-input
                    v-model="HeaderInfo.MobileLogin.verifySmsCode"
                    class="w-p-100 login-input"
                    :placeholder="$t('TranspertGlobalKey.PleaseInputAPassword')"
                    type="password"
                    @keypress.enter.native="onLoginByAccount"
                />
              </oms-form-item>
              <el-button
                  :loading="loading"
                  class="login-button button-fill oms-button-style mt-40 mb-10"
                  type="primary"
                  @click="onLoginByAccount"
              >{{ $t("TranspertGlobalKey.Login") }}
              </el-button>
            </oms-row>
          </el-tab-pane>
          <div
              :class="['flex', 'center', 'middle']"
              v-if="loginTab !== 'qrcode'"
              style="cursor: pointer"
              @click="agreeChange"
          >
            <div class="agree-btn" :class="{ 'is-agree': isAgree }"></div>
            <p class="block fs-12 lh-18">
              {{ $t("TranspertGlobalKey.click") }}&nbsp;“{{ $t("TranspertGlobalKey.Login") }}/{{
                $t("TranspertGlobalKey.register")
              }}”，{{
                $t("TranspertGlobalKey.ThatMeansConsent")
              }}<a class="link" style="color: #275df1" @click="serviceContentVisible = true"
            >《{{ $t("TranspertGlobalKey.UserServiceAgreement") }}》</a
            >
            </p>
          </div>

          <el-tab-pane name="qrcode" :label="$t_reserve('扫码登录')">
            <!-- <div class="flex between center">
              <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByWechat'">{{
                $t('TranspertGlobalKey.WeChatLogin')
              }}</a>
              <div style="display: flex; align-items: center; margin-top: 5px" v-if="ShareName">
                <img src="@/assets/images/Exclusive-customer.png" style="width: 30px; height: 30px" />
                <div style="padding-left: 5px">{{ $t('TranspertGlobalKey.ExclusiveCustomer') }}：{{ ShareName }}</div>
              </div>
            </div> -->
            <div class="mt-20">
              <div class="w-p-100">
                <wwlogin @init="wechatConfig = $event" ref="WW_LOGIN" @login="onSetToken" @scanned="onLoginByWechat"/>
                <!-- <div class="relative z-index-max mt-20">
              <hr />
              <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByPhone'"
                >{{ $t('TranspertGlobalKey.UseVerificationCode') }}/{{ $t('TranspertGlobalKey.WeChatLogin') }}</a
              >
            </div> -->
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane name="phone" :label="$t_reserve('验证码登录')">
            <oms-row
                ref="LOGIN_ROW"
                form
                :rules="INFO_RULES.MOBILE_LOGIN"
                class="mt-20"
                :model="HeaderInfo.MobileLogin"
                form-item-margin="0 0 20px 0"
            >
              <span class="login-tips">{{ $t_reserve("验证即登录，未注册将自动创建运达人账号") }}</span>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.areaAndPhone">
                <div class="flex combine-select-input">
                  <select-area-number v-model="HeaderInfo.MobileLogin.area" v-flex-property.basis="'120px'"/>
                  <el-input
                      v-model="HeaderInfo.MobileLogin.phone"
                      class="fill login-input"
                      :placeholder="$t('TranspertGlobalKey.PleaseEnterYourMobileNumber')"
                  />
                </div>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifyImgCode">
                <el-input
                    class="w-p-100 login-input"
                    v-model="HeaderInfo.MobileLogin.verifyImgCode"
                    :placeholder="$t('TranspertGlobalKey.PleaseEnterTheGraphicVerificationCode')"
                    @keypress.enter.native="$refs.VERIFY_SMS_CODE.onGetSmsCode()"
                >
                  <a slot="suffix" class="verify-button flex center middle h-p-100">
                    <img
                        :src="OMS_API.verifyImgCode + '?v=' + UUID"
                        @click="onRefreshImgCode()"
                        alt=""
                        style="width: 83px; height: 32px"
                    />
                  </a>
                </el-input>
              </oms-form-item>
              <oms-form-item :prop="INFO_RULES.MOBILE_RULES_PROP.verifySmsCode">
                <el-input
                    class="w-p-100 login-input"
                    v-model="HeaderInfo.MobileLogin.verifySmsCode"
                    :placeholder="$t('TranspertGlobalKey.PleaseEnterSMSVerificationCode')"
                    @keypress.enter.native="onLoginByPhone"
                >
                  <verify-sms-code ref="VERIFY_SMS_CODE" slot="suffix" :send="onGetSmsCode"/>
                </el-input>
              </oms-form-item>
              <el-button
                  class="login-button button-fill mt-20 mb-10 oms-button-style"
                  :loading="loading"
                  :disabled="loading"
                  type="primary"
                  @click="onLoginByPhone"
              >
                {{ $t("TranspertGlobalKey.Login") }}
                <!-- /{{ $t('TranspertGlobalKey.register') }} -->
              </el-button>

              <!-- <hr class="mtb-10" /> -->
            </oms-row>
          </el-tab-pane>
          <!-- <el-tab-pane name="wechat" :label="$t('TranspertGlobalKey.WeChatLogin')">
            <div class="flex center column middle mt-20" style="padding-top: 2px">
              &lt;!&ndash; <el-avatar :size="300" shape="square" class="mt-5" />&ndash;&gt;
              <div class="w-p-100">
                <wwlogin
                  @init="wechatConfig = $event"
                  ref="WW_LOGIN"
                  v-if="loginTab === 'wechat'"
                  @login="onSetToken"
                  @scanned="onLoginByWechat"
                />
              </div>
              <span class="mt-20 lh-24 fs-14 mb-20 fc-777">{{
                $t('TranspertGlobalKey.PleaseUseWeChatScanningQRCodeToBind')
              }}</span>
            </div>
          </el-tab-pane> -->

        </el-tabs>
      </div>

      <oms-row
          ref="ACCOUNT_ROW"
          class="pt-10"
          :model="HeaderInfo.MobileLogin"
          :rules="INFO_RULES.ACCOUNT_LOGIN"
          form
          v-else-if="accountContainerType === 'loginByAccount'"
          form-item-margin="0 0 20px 0"
      >
        <oms-form-item>
          <el-input
              v-model="HeaderInfo.MobileLogin.phone"
              class="w-p-100"
              :placeholder="$t('TranspertGlobalKey.PleaseEnterTheAccountNumber')"
          />
        </oms-form-item>
        <oms-form-item>
          <el-input
              v-model="HeaderInfo.MobileLogin.verifySmsCode"
              class="w-p-100"
              :placeholder="$t('TranspertGlobalKey.PleaseInputAPassword')"
              type="password"
              @keypress.enter.native="onLoginByAccount"
          />
        </oms-form-item>
        <el-button
            :loading="loading"
            class="button-fill oms-button-style mt-40 mb-50"
            type="primary"
            @click="onLoginByAccount"
        >{{ $t("TranspertGlobalKey.Login") }}
        </el-button>
        <hr/>
        <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByPhone'"
        >{{ $t("TranspertGlobalKey.UseVerificationCode") }}/{{ $t("TranspertGlobalKey.WeChatLogin") }}</a
        >
      </oms-row>

      <!-- <oms-row class="pt-10" v-else-if="accountContainerType === 'loginByWechat'">
        <div class="flex center column middle mt-20" style="padding-top: 2px">
          <div class="w-p-100">
            <wwlogin
              @init="wechatConfig = $event"
              ref="WW_LOGIN"
              v-if="accountContainerType === 'loginByWechat'"
              @login="onSetToken"
              @scanned="onLoginByWechat"
            />
            <div class="relative z-index-max mt-20">
              <hr />
              <a class="link lh-24 fs-14" @click="accountContainerType = 'loginByPhone'"
                >{{ $t('TranspertGlobalKey.UseVerificationCode') }}/{{ $t('TranspertGlobalKey.WeChatLogin') }}</a
              >
            </div>
          </div>
        </div>
      </oms-row> -->
    </oms-dialog>
    <oms-dialog
        :visible.sync="serviceContentVisible"
        :title="$t('TranspertGlobalKeyText.UserAgreementAndPrivacyStatement')"
    >
      <div class="fs-18 fc-333">
        <span class="u-text text-1">{{ $t("TranspertGlobalKeyText.agreeContract") }}。</span>
        <span class="u-text text-1">{{ $t("TranspertGlobalKeyText.agreeContract1") }}</span>
        <span class="u-text text-1">{{ $t("TranspertGlobalKeyText.agreeContract2") }}</span>
        <span class="u-text text-2">{{ $t("TranspertGlobalKeyText.agreeContract3") }}</span>
        <span class="u-text text-2">{{ $t("TranspertGlobalKeyText.agreeContract4") }}</span>
        <span class="u-text text-1">3、{{ $t("TranspertGlobalKey.account") }}</span>
        <span class="u-text text-2">3.1{{ $t("TranspertGlobalKeyText.agreeContract5") }}。</span>
        <span class="u-text text-2">3.2{{ $t("TranspertGlobalKeyText.agreeContract6") }}。</span>
        <span class="u-text text-2">3.3{{ $t("TranspertGlobalKeyText.agreeContract7") }}。</span>
        <span class="u-text text-2">3.4{{ $t("TranspertGlobalKeyText.agreeContract8") }}。</span>
        <span class="u-text text-2">3.5{{ $t("TranspertGlobalKeyText.agreeContract9") }}。</span>
        <span class="u-text text-1">4、{{ $t("TranspertGlobalKey.ReasonableUseOfUserInformation") }}</span>
        <span class="u-text text-2">4.1{{ $t("TranspertGlobalKeyText.agreeContract10") }}。</span>
        <span class="u-text text-2">4.2{{ $t("TranspertGlobalKeyText.agreeContract11") }}。</span>
        <span class="u-text text-2">4.3{{ $t("TranspertGlobalKeyText.agreeContract12") }}。</span>
        <span class="u-text text-1">5、{{ $t("TranspertGlobalKey.Disclaimer") }}</span>
        <span class="u-text text-2">5.1 {{ $t("TranspertGlobalKeyText.agreeContract13") }}。</span>
      </div>
    </oms-dialog>
    <!-- 绑定手机号 -->
    <oms-dialog
        destroy-on-close
        :width="507"
        :visible.sync="bindPhoneVisible"
        :title="$t('TranspertGlobalKey.BindMobileNumber')"
        @close="onRefreshWechatQrCode"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <oms-row ref="BIND_FORM" form :model="bindUser" :rules="INFO_RULES.BIND_PHONE" form-item-margin="0">
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.areaAndPhone">
          <div class="flex combine-select-input">
            <select-area-number v-model="bindUser.area" v-flex-property.basis="'120px'"/>
            <el-input
                v-model="bindUser.phone"
                class="fill"
                :placeholder="$t('TranspertGlobalKey.PleaseEnterYourMobileNumber')"
            />
          </div>
        </oms-form-item>
        <oms-form-item :prop="INFO_RULES.BIND_PHONE_RULES_PROP.verifySmsCode">
          <el-input
              v-model="bindUser.verifySmsCode"
              class="flex center mt-20"
              :placeholder="$t('TranspertGlobalKey.PleaseEnterTheVerificationCode')"
          >
            <verify-sms-code
                slot="suffix"
                class="verify-button link"
                :count-key="`Bind_Phone_VerifyCode`"
                :send="onGetSmsCode.bind(this, 1)"
            />
          </el-input>
        </oms-form-item>
        <div class="flex mt-40">
          <el-button :loading="bindLoading" type="primary" class="button-fill" @click="onBindPhone(1)">
            <span class="fs-18">{{ $t("TranspertGlobalKey.binding") }}</span>
          </el-button>
        </div>
      </oms-row>
    </oms-dialog>
    <unopen-dialog :dialog-visible.sync="dialogVisible"/>
  </header>
</template>

<script>
import Logo                   from "@/components/Logo";
import {rules}                from "@/mixins";
import {mapGetters, mapState} from "vuex";
import VerifySmsCode          from "@/components/VerifySmsCode";
import notify                 from "@/mixins/notify";
import Wwlogin                from "@/components/omsExtend/wwlogin";
import UnopenDialog           from "@/components/UnopenDialog";
import {tryLoginByWechat}     from "@/api/user";
import SelectAreaNumber       from "@/components/omsExtend/SelectAreaNumber";
import {getShareInfo}         from "@/api/user";

export default {
  name: "AppHeader",
  components: {SelectAreaNumber, Wwlogin, VerifySmsCode, Logo, UnopenDialog},
  // watch: {
  //   bindPhoneVisible(val) {
  //     setTimeout(() => {
  //       alert("刷新");
  //       this.onRefreshWechatQrCode();
  //     }, 3000);
  //   },
  //   loginTab(val) {
  //     if (val === "wechat") {
  //       setTimeout(() => {
  //         alert('模拟扫描成功');
  //         this.onLoginByWechat("测试");
  //       },2000);
  //     }
  //   },
  // },
  data() {
    return {
      bindPhoneVisible: false,
      dialogVisible: false,
      bindPhoneType: "",
      wechatConfig: {},
      loading: false,
      bindLoading: false,
      logoutLoading: false,
      currentDate: null,
      smsCooldown: +sessionStorage.getItem("header_sms_cool_down") || 0,
      UUID: this.$guid().replace("-", ""),
      serviceContentVisible: false,
      accountVisible: false,
      accountContainerType: "loginByPhone",
      loginTab: "account",
      hoverState: {
        home: false,
        workSpace: false,
        controlOrder: false,
        productService: false,
        business: false,
        solution: false,
        consult: false,
        searchTool: false
      },
      // ShareId: "",
      ShareName: "",
      isSearch: false,
      searchText: "",
      hoverIndex: -1,
      isAgree: true
    };
  },
  mounted() {
    this.$event?.on?.("showLoginDialog", this.onClickToAccountContainer);
  },
  created() {
    const langType = window.localStorage.getItem("lang");
    if (langType) {
      this.handleCommand(langType);
    }
    this.getShareInfo();
  },
  methods: {
    setCenterType(type) {
      this.$store.dispatch("user/GET_CENTERTYPE", type);
    },
    getShareInfo() {
      if (!this.$store.getters.isLogin) {
        if (this.ShareId) {
          this.$store.dispatch("GET_SHARE_INFO", this.ShareId).then(() => {
            this.ShareName = this.$store.state.shareName;
            if (this.ShareName) {
              this.onClickToAccountContainer("loginByPhone");
            }
          });
          /*getShareInfo(this.ShareId).then(shareName => {
            this.ShareName = share
            Name;
          }).catch(error => {
            this.notifyError("获取专属业务员的信息失败，原因：" + (error.message || "网络请求失败"));
          });*/
        }
      }
    },
    handleCommand(e) {
      window.localStorage.setItem("lang", e);

      const proxy = {
        "zh-CN": () => this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "zh-CN"),
        "zh-TW": () => this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "zh-TW"),
        "ru": () => this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "ru"),
        "en": () => this.$store.dispatch("lang/ASYNC_LOAD_LANG_JSON", "en"),
      }[e];

      return proxy();
    },
    async onRefreshWechatQrCode() {
      this.loginTab = "";
      await this.$forceUpdate();
      this.loginTab = "wechat";
    },
    async onGetSmsCode(type) {
      if (type === 1) {
        // 绑定手机号
        let valid = await this.$refs.BIND_FORM.validateFields(this.INFO_RULES.BIND_PHONE_RULES_PROP.areaAndPhone);

        if (valid[0]) {
          const ok = await this.OMS_API.SendBindPhoneVerifyCode(this.bindUser.area, this.bindUser.phone);
          if (process.env.NODE_ENV === "development") {
            this.notifySuccess(`${this.$t("TranspertGlobalKey.Code")}：${ok}`);
            return true;
          }
          if (ok === true) {
            this.notifySuccess(this.$t("TranspertGlobalKey.SendingSucceeded"));
          } else {
            this.notifyError(ok?.message || this.$t("TranspertGlobalKey.FailedToSendPleaseTryAgainLater"));
            return false;
          }
        }
      } else {
        this.$refs.LOGIN_ROW.clearValidate();

        const loginInfo = this.HeaderInfo.MobileLogin;
        const results = await this.$refs.LOGIN_ROW.validateFields(
            this.INFO_RULES.MOBILE_RULES_PROP.areaAndPhone,
            this.INFO_RULES.MOBILE_RULES_PROP.verifyImgCode
        );
        if (results[0] && results[1]) {
          const result = await this.OMS_API.sendSmsCode({
            countryCode: loginInfo.area,
            phone: loginInfo.phone.replace(/\s*/g, ""),
            code: loginInfo.verifyImgCode,
            isInternational: !!loginInfo.area && +loginInfo.area !== 86 ? "T" : "F",
            verifyImgCode: "T"
          });

          if (result.success === false) {
            this.notifyError(
                result.message || this.$t("TranspertGlobalKey.FailedToObtainTheVerificationCodePleaseTryAgainLater")
            );
            return false;
          }

          if (process.env.NODE_ENV === "development") {
            this.notifySuccess(`${this.$t("TranspertGlobalKey.Code")}:${result}`);
            return true;
          }

          if (result === true) {
            return true;
          }

          if (result === false) {
            this.notifyError(this.$t("TranspertGlobalKey.FailedToSend"));
            return false;
          }

          const errorMessage =
              result?.message || this.$t("TranspertGlobalKey.AnErrorOccurredInTheRequestPleaseTryAgainLater");

          this.notifyError(errorMessage);
          return false;
        }
        return false;
      }
      // this.smsLoading = false;
      // if (ok) {
      //   this.smsCooldown = Date.now() + 60 * 1000;
      //   sessionStorage.setItem("header_sms_cool_down", this.smsCooldown);
      //
      //   const loop = () => {
      //     this.currentDate = Date.now();
      //     if (this.DISPLAY_SMS_COOL_DOWN <= 0) return;
      //     delay(500).then(loop);
      //   };
      //
      //   loop();
      // } else {
      //   // this.$refs.LOGIN_ROW.row.validateField(this.INFO_RULES.MOBILE_RULES_PROP.verifySmsCode);
      // }
    },
    async onSetToken(res) {
      //todo: 扫码直接登录成功
      if (res?.success === false) {
        return this.notifyError(res?.message || this.$t("TranspertGlobalKey.WeChatLoginFailed"));
      }
      await this.$store.dispatch("user/LOGIN_SUCCESS", res);
      this.notifySuccess(this.$t("TranspertGlobalKey.LoginSucceeded"));
      this.accountVisible = false;
      this.bindPhoneVisible = false;
      await this.$refs.WW_LOGIN.updateUrl();
    },
    async onBindPhone(type) {
      if (type === 1) {
        this.bindLoading = true;
        const res = await this.OMS_API.tryLoginByWechat(this.wechatConfig.wechatUserId, {
          phone: this.bindUser.phone,
          countryCode: this.bindUser.area,
          verifyCode: this.bindUser.verifySmsCode,
          shareid: this.ShareId
        });
        this.bindLoading = false;

        if (!res || (res?.success === false && res?.message)) {
          return this.notifyError(res?.message || this.$t("TranspertGlobalKey.LoginFailed"));
        }

        const message = await this.$store.dispatch("user/WECHAT_LOGIN", res);
        if (message) {
          return this.notifyError(res?.message || this.$t("TranspertGlobalKey.LoginFailed"));
        }

        this.notifySuccess(this.$t("TranspertGlobalKey.LoginSucceeded"));
        this.accountVisible = false;
        this.bindPhoneVisible = false;
        await this.$refs.WW_LOGIN.updateUrl();
      }
    },
    //刷新验证码
    onRefreshImgCode() {
      this.UUID = this.$guid().replace("-", "");
    },
    async onLoginByWechat(code) {
      this.bindUser.phone = "";
      this.bindUser.area = "86";
      this.bindUser.verifySmsCode = "";
      this.bindPhoneVisible = true;
      this.bindPhoneType = "wechat";
      // 初始的state不对，所以需要回调返回来的code
      this.wechatConfig.wechatUserId = code;
      //15811823978
      // await this.$refs.WW_LOGIN.updateUrl();
      // const wxConfig = this.wechatConfig;
      // const response = await tryLoginByWechat(wxConfig.state);
    },
    // 账号密码登录
    async onLoginByAccount() {
      if (!this.isAgree) return this.$notify.warning(this.$t("请阅读并确认同意《用户服务协议》"));
      const type = "login.account";
      const loginInfo = this.HeaderInfo.MobileLogin;

      const ok = await this.$refs.ACCOUNT_ROW.validate();
      if (ok) {
        this.loading = true;
        const message = await this.$store.dispatch("user/USER_LOGIN", {
          type,
          user: loginInfo,
          ShareId: this.ShareId
        });
        // debugger
        this.loading = false;
        if (message) {
          return this.notifyError(message);
        }

        this.notifySuccess(this.$t("TranspertGlobalKey.LoginSucceeded"));
        this.accountVisible = false;
      }
    },
    // 手机号登录/注册
    async onLoginByPhone() {
      if (!this.isAgree) return this.$notify.warning(this.$t("请阅读并确认同意《用户服务协议》"));
      const type = "login.phone";
      const loginInfo = this.HeaderInfo.MobileLogin;

      const ok = await this.$refs.LOGIN_ROW.validate();
      if (ok) {
        this.loading = true;
        const message = await this.$store.dispatch("user/USER_LOGIN", {
          type,
          user: loginInfo,
          ShareId: this.ShareId
        });
        this.loading = false;
        this.onRefreshImgCode();

        if (message) {
          return this.notifyError(message);
        }

        this.notifySuccess(this.$t("TranspertGlobalKey.LoginSucceeded"));
        this.accountVisible = false;
      }
    },
    onClickToAccountContainer(type = "loginByPhone") {
      this.accountVisible = true;
      this.accountContainerType = type;
    },
    async onLogout() {
      this.logoutLoading = true;
      const message = await this.$store.dispatch("user/LOG_OUT");
      this.logoutLoading = false;
      if (message) {
        this.notifyError(message);
      } else {
        this.notifySuccess(this.$t("TranspertGlobalKey.ExitSucceeded"));
        this.$router.go(0);
      }
    },
    agreeChange() {
      this.isAgree = !this.isAgree;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    toServiceLink(item) {
      if (item.disabled) {
        this.showDialog();
      }
      this.$router.push({name: item.name, params: item.params});
    }
  },
  mixins: [rules, notify],
  computed: {
    ShareId() {
      return this.$route.query.ShareId;
    },
    IS_WORKPAGE() {
      return this.pageType === "workPage";
    },
    POPOVER_STYLE() {
      if (this.$i18n.locale === "zh-CN" || this.$i18n.locale === "zh-TW") {
        return "230px";
      } else {
        return "190px";
      }
    },
    // isDevelopment(){
    //   return process.env.NODE_ENV === 'development';
    // },
    ...mapGetters(["isLogin", "user", "accessState"]),
    NICK_NAME() {
      return this.user.RealName; //|| this.user.NickName || this.user.Customer?.CusFullNameChs || this.user.WechatUser?.Nickname ||  this.user.Account;
    },
    // 转化成显示用的数字
    DISPLAY_SMS_COOL_DOWN() {
      if (this.smsCooldown <= 0) {
        return 0;
      }

      const time = parseInt((this.smsCooldown - this.currentDate) / 1000);

      if (time <= 0) {
        return 0;
      }

      return time;
    },
    ACCOUNT_TITLE() {
      if (this.accountContainerType === "loginByPhone") {
        return null;
      } else if (this.accountContainerType === "loginByAccount") {
        return this.$t("TranspertGlobalKey.AccountPasswordLogin");
      }

      return "";
    },
    PRODUCT_SERVICE_LI_HOVER() {
      return ["FreightQuery", "FreightOrder", "ReOrder"].includes(this.$route.name);
    },
    BUSINESS_LI_HOVER() {
      return ["BusinessTrack"].includes(this.$route.name);
    },
    WORK_SPACE_LI_HOVER() {
      return [
        "UserInformation",
        "UserOrder",
        "Workbench",
        "WorkOrderTodo",
        "OrderList",
        "WorkOrderList",
        "WorkbenchOrderInfo",
        "WorkbenchWorkOrderInfo"
      ].includes(this.$route.name);
    },
    SEARCH_TOOL_HOVER() {
      return ["SearchList"].includes(this.$route.name);
    },
    PRODUCT_SERVICE_LINK_LIST() {
      return [
        {
          label: this.$t("TranspertGlobalKey.railway1"),
          type: "railway"
        },
        {
          label: this.$t("TranspertGlobalKey.automobileTransport1"),
          type: "truck"
        },
        {
          label: this.$t("TranspertGlobalKey.seaTransportation1"),
          type: "sea"
        },
        {
          label: this.$t("TranspertGlobalKey.airTransport1"),
          type: "air"
        },
        {
          label: this.$t("TranspertGlobalKey.MultiTypeTransportation1"),
          type: "multiTrans"
        }
      ];
    },
    NOT_ALLOW_HOVER() {
      return !this.hoverState.keyEntries().some((key) => !!this.hoverState[key]);
    },
    USER_SET_LIST() {
      return [
        {
          name: "UserInformation",
          centerType: "base",
          src: require("@/assets/images/header-user-info@2x.png"),
          width: "16px",
          height: "16px",
          text: "基本资料"
        },
        {
          name: "UserInformation",
          centerType: "business",
          src: require("@/assets/images/header-user-bussines@2x.png"),
          width: "15px",
          height: "15px",
          text: "企业认证"
        },
        {
          name: "UserInformation",
          centerType: "safe",
          src: require("@/assets/images/header-user-set@2x.png"),
          width: "14px",
          height: "14px",
          text: "安全设置"
        }
      ];
    },
    SERVICE_MENU_LIST() {
      return [
        {
          src: "railway",
          text: "铁运服务",
          name: "ReOrder",
          params: {type: "railway"},
          width: "16px",
          height: "17px"
        },
        {
          src: "ship",
          text: "海运服务",
          name: "ReOrder",
          params: {type: "sea"},
          width: "21px",
          height: "17px"
        },
        {
          src: "truck",
          text: "汽运服务",
          name: "ReOrder",
          params: {type: "truck"},
          width: "20px",
          height: "16px"
        },
        {
          src: "air",
          text: "空运服务",
          name: "ReOrder",
          params: {type: "air"},
          width: "23px",
          height: "16px"
        },
        {
          src: "multitrain",
          text: "多式联运",
          name: "ReOrder",
          params: {type: "multiTrans"},
          width: "18px",
          height: "18px"
        },
        {
          src: "oy",
          disabled: true,
          text: "欧亚卡航",
          name: "",
          params: {type: "oy"},
          width: "17px",
          height: "17px"
        },
        {
          src: "boxSaler",
          disabled: true,
          text: "集装箱租售",
          name: "",
          params: {type: "boxSale"},
          width: "19px",
          height: "20px"
        }
      ];
    },
    SEARCH_MENU_LIST() {
      return [
        {
          src: "searchList",
          text: "正面清单查询",
          name: "SearchList",
          params: {searchType: "list"},
          width: "18px",
          height: "15px"
        },
        {
          src: "searchHs",
          text: "HS-Code查询",
          name: "SearchList",
          params: {searchType: "hsCode"},
          width: "17px",
          height: "17px"
        },
        {
          src: "searchHs",
          text: "FBA仓库查询",
          name: "SearchList",
          params: {searchType: "fba"},
          width: "17px",
          height: "17px"
        }
      ];
    },
    MENU_IMG() {
      return (src, isHover) => {
        return require(`@/assets/images/header-menu-${isHover ? `${src}-hover` : src}@2x.png`);
      };
    }
  },

  beforeDestroy() {
    this.$event.off("showLoginDialog", this.onClickToAccountContainer);
    document.documentElement.removeEventListener("mousemove", this.over);
  },
  props: {
    fixed: Boolean,
    absolute: Boolean,
    space: Boolean,
    isScroll: Boolean,
    pageType: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style lang="scss">
.app-header_popper-style[x-placement='bottom'],
.app-header_popper-style[x-placement='bottom-end'] {
  padding: 0;
  margin-top: 15px;

  &.avatar {
    margin-top: 18px;
  }

  .popper__arrow {
    display: none;
  }

  &.solution {
    padding: 30px 40px;
  }

  &.business {
    padding: 15px 30px;

    .flex {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }

  &.app-header-transition-enter-active,
  &.app-header-transition-leave-active,
  &.app-header-transition-enter-to,
  &.app-header-transition-leave-to {
    transition: 0.2s linear;
  }

  &.app-header-transition-enter {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.app-header-transition-enter,
  &.app-header-transition-leave-to {
    opacity: 0;
    transform: translate(0, -3px);
  }
}

.header_popper-style[x-placement='bottom'],
.header_popper-style[x-placement='bottom-end'] {
  padding: 0;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  left: 0 !important;
  top: 60px !important;
  background-color: #f4f4f4;
  box-shadow: inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);

  &.avatar {
    margin-top: 18px;
  }

  .popper__arrow {
    display: none;
  }

  &.solution {
    padding: 30px 40px;
  }

  &.business {
    // padding: 15px 30px;

    .flex {
      flex-grow: 1;
      flex-basis: 25%;
    }
  }

  &.app-header-transition-enter-active,
  &.app-header-transition-leave-active,
  &.app-header-transition-enter-to,
  &.app-header-transition-leave-to {
    transition: 0.1s linear;
  }

  &.app-header-transition-enter {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.app-header-transition-enter,
  &.app-header-transition-leave-to {
    opacity: 0;
    transform: translate(0, -5px);
  }
}

.row-ul {
  // position: absolute;
  // right: 260px;
  // width: 40px;
  margin-right: 28px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  // ::v-deep .el-dropdown {
  //   .el-dropdown-item:hover {
  //     cursor: pointer;
  //     background-color: #B32574;
  //   }
  // }

  .el-dropdown-link {
    // cursor: pointer;
    color: #fff;
    font-size: calc(14px - var(--default_font-size_offset));
    white-space: nowrap;
  }

  .icon {
    height: 24px;
    width: 24px;
    // margin: 0px 4px 0 0;
  }

  // transform: translateY(20px);
  .internationalization-row {
    .internationalization {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .text {
        font-size: calc(18px - var(--default_font-size_offset));
        color: #fff;
        margin: 0 4px;
      }

      .arrow {
        width: 10px;
        height: 6px;
        margin-top: 8px;
      }
    }
  }
}

:root {
  --locale-special-size: 0px;
}

[lang="en"], [lang="ru"] {
  --locale-special-size: 2px;
}
</style>
<style lang="scss" scoped>
.header-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(16px - var(--default_font-size_offset));
  position: fixed;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 4;
  background: #fff;
  transition: all 0.8s;
  transform: translateX(100%);

  &.isSearch {
    transform: translateX(0%);
  }

  .search-close-icon {
    width: 18px;
    transform: translateX(120px);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translateX(120px) scale(1.2);
    }
  }

  .header-search {
    position: relative;

    .header-search-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 118px;
      height: 52px;
      color: #fff;
      letter-spacing: 5px;
      font-weight: 400;
      background: #b32574;
      border-radius: 0px 26px 26px 0px;
      transition: all 0.3s;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  .header-search-input {
    ::v-deep {
      .el-input__inner {
        width: 758px;
        height: 52px;
        font-size: calc(18px - var(--default_font-size_offset));
        border-radius: 26px !important;
      }

      .el-input__icon {
        font-size: calc(18px - var(--default_font-size_offset));
        transform: translateY(3px);
      }
    }
  }
}

.isScroll {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);
}

.name-text {
  text-align: left;
  font-size: calc(14px - var(--default_font-size_offset));
  min-width: 200px !important;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  white-space: pre-wrap;
  word-break: break-all;
}

.text-color {
  color: #333 !important;
}

.text-fff {
  color: #fff !important;
}

.text-fff-opacity {
  // color: #fff !important;
  opacity: 0.85 !important;
}

header {
  $HEADER_HEIGHT: 72px;
  font-size: calc(16px - var(--default_font-size_offset));

  &.ru {
    font-size: calc(14px - var(--default_font-size_offset));
  }

  position: relative;
  width: 100%;
  height: $HEADER_HEIGHT;
  z-index: 4;

  .app-link {
    // height: $HEADER_HEIGHT;
    // line-height: $HEADER_HEIGHT;
  }

  &.work-page {
    .work-page-title {
      width: 280px;
      font-size: calc(20px - var(--default_font-size_offset) - var(--locale-special-size));
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .header-space {
    height: $HEADER_HEIGHT;
    display: none;
  }

  &.fixed {
    .header {
      position: fixed;
      width: 100vw;
      left: 0;
      top: 0;
    }
  }

  .header-right-wrap {
    display: flex;
    align-items: center;

    .search-icon-wrap {
      position: relative;
      margin-right: 28px;
      cursor: pointer;

      &:hover .search-icon {
        transform: translateY(-5px);
      }

      .search-icon {
        width: 24px;
        height: 24px;
        transition: all 0.2s;
      }

      span {
        font-size: calc(14px - var(--default_font-size_offset));
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 200%);
      }

      &:hover span {
        opacity: 1;
      }
    }

    .header-language-wrap {
      position: relative;
      cursor: pointer;

      &:hover .language-icon {
        transform: translateY(-5px);
      }

      .language-icon {
        width: 24px;
        height: 24px;
        transition: all 0.2s;
      }

      &:hover span {
        opacity: 1;
      }

      // display: flex;
      // flex-direction: column;
      // align-items: center;
      span {
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 200%);
      }
    }

    .login-wrap {
      position: relative;

      .noLogin-text {
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 200%);
      }

      &:hover .noLogin-text {
        opacity: 1;
      }
    }
  }

  &.absolute {
    position: absolute;

    .header,
    .header > .container {
      background: transparent;
    }

    .header .menu-ul {
      color: #333;
    }

    .header {
      .menu-li {
        &.on {
          color: $FONT_COLOR;
        }

        ::v-deep a > span {
          word-break: keep-all;
          max-width: 80px;
          text-align: center;
          position: relative;

          &:before {
            background: $BASE_COLOR !important;
            position: absolute;
            bottom: 0;
            left: 50%;
            // transform: translateX(-50%);
            margin-left: -50%;
          }
        }
      }
    }
  }

  &.fixed {
    height: 0;

    .header {
      position: fixed;
    }

    .header .menu-ul {
      color: #333;
    }

    .header {
      .menu-li {
        ::v-deep a > span {
          word-break: keep-all;
          max-width: 80px;
          text-align: center;
          position: relative;

          &:before {
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            // transform: translateX(-50%);
            margin-left: -50%;
          }
        }
      }
    }
  }

  &.space {
    height: $HEADER_HEIGHT;

    .header-space {
      display: block;
    }
  }

  .header {
    .menu-li {
      &.on {
        color: $FONT_COLOR;
      }

      ::v-deep a > span {
        word-break: keep-all;
        max-width: 80px;
        text-align: center;
        position: relative;

        &:before {
          background: $BASE_COLOR !important;
          position: absolute;
          bottom: 0;
          left: 50%;
          // transform: translateX(-50%);
          margin-left: -50%;
        }
      }
    }
  }

  .header {
    height: $HEADER_HEIGHT;
    // background: $BASE_COLOR;
    background: #fff;
    // transition: background-color 0.2s linear;

    > .container {
      margin: 0 auto;
      height: 100%;
      //background: $BASE_COLOR;
      overflow: hidden;
      //transition: .2s linear;

      .flex {
        height: 100%;
      }
    }

    .menu-li {
      position: relative;
      height: 100%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      transition: background-color 0.2s linear;
      background-color: transparent;

      ::v-deep a {
        padding: 0 17px;
      }

      &.on {
        //background-color: #2961b9;
        color: $FONT_COLOR;

        ::v-deep a > span {
          &:before {
            transform: scale(1, 1);
          }
        }
      }

      ::v-deep a > span {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #fff;
          margin-bottom: -10px;
          transition: 0.2s linear;
          transform: scale(0, 1);
          transform-origin: center;
        }
      }
    }

    .menu-ul {
      position: absolute;
      top: 0;
      margin-left: var(--logo-size);
      color: #fff;
      max-width: 700px;
    }
  }
}

.banner {
  // height: 400px;
  position: relative;
  // background-image: url('@/assets/images/home-page-backimg.png');
  // background-size: cover;
  .banner-button-left {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    width: 43px;
    transform: translateY(-50%) rotate(180deg);
  }

  .banner-button-right {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 0;
    width: 43px;
    transform: translateY(-50%);
  }

  .banner-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;

    ::v-deep .pagination {
      bottom: 21px;
    }
  }

  .banner-group-item {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.display-menu-list {
  height: 100%;

  p {
    color: #333;
    padding-bottom: 10px;
    font-size: calc(18px - var(--default_font-size_offset));
    font-weight: 500;
    white-space: pre-wrap;
    min-height: 27px;
  }

  ::v-deep a {
    color: #333;
    // padding: 10px 0;
    // display: block;
    font-size: calc(16px - var(--default_font-size_offset));
    font-weight: 400;
    // line-height: 24px;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: $FONT_COLOR;
    }

    &.active {
      color: darken($FONT_COLOR, 5%);
    }

    // &.product {
    //   padding: 0 21px 0 7px;
    //   line-height: 48px;
    //   min-height: 48px;
    //   transition: 0.2s linear;
    //   text-align: left;
    //   white-space: pre-wrap;

    //   &:hover {
    //     background: #ededed;
    //     color: #333;
    //     font-weight: 500;
    //   }

    //   &:active {
    //     background: darken(#ededed, 10%);
    //     color: #000;
    //     font-weight: 500;
    //   }
    // }
  }

  .header-menu {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    white-space: nowrap;
    // align-items: center;
    .header-menu-item {
      flex-basis: 200px;
      flex-grow: 0;

      span {
        white-space: pre-wrap;
        display: block;
        line-height: 14px;
        word-break: break-word;
        text-align: left;
      }

      img {
        margin-right: 9px;
      }

      &.now-page {
        color: #b32574;
      }
    }
  }

  .contact-us {
    .line-item {
      padding: 20px 30px;

      &:not(:last-child) {
        border-bottom: 0.5px solid #efefef;
      }

      .line-item-content {
        margin-top: 8px;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          margin-right: 6px;
        }
      }
    }
  }
}

.user-info-wrap {
  padding: 15px 0px;
  min-width: 310px;
  box-sizing: border-box;

  .saler-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 0 20px;

    p {
      font-size: calc(14px - var(--default_font-size_offset));
      font-weight: 400;
      color: #777777;
      line-height: 20px;
      padding: 2px 0;
    }
  }

  .control-list {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    font-size: calc(14px - var(--default_font-size_offset));
    color: #333;
    font-weight: 400;
    // padding: 10px 0;

    // li > a {
    //   padding: 10px 50px 10px 20px;
    //   box-sizing: border-box;
    // }

    .set-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 17px;
      padding: 10px 20px;

      span {
        flex-grow: 1;
        margin-left: 12px;
      }

      img {
      }
    }
  }

  .log-out {
    display: block;
    padding: 10px 20px 0;
    text-align: right;
    cursor: pointer;
    color: #1b70d8;
  }
}

// .oms-extend__clear-tabs-effect.tab-center {
//   ::v-deep .el-tabs__nav.is-top {
//     float: none;
//     margin: 0 auto;
//     text-align: center;
//   }
// }
::v-deep .tab-style-none-ru .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__item {
  font-size: calc(12px - var(--default_font-size_offset));
  padding: 0 7px;
}

.account-by-phone-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 72px 55px 56px 12px;
  max-height: 500px;
  box-sizing: border-box;

  > img {
    width: 436px;
    height: 372px;
  }

  ::v-deep .login-tabs {
    width: 354px;
    height: 372px;

    .agree-btn {
      width: 16px;
      height: 16px;
      border: 1px solid #b32574;
      border-radius: 50%;
      transform: scale(0.5);

      &.is-agree {
        background: url('@/assets/images/header-login-confirm@2x.png') no-repeat;
        background-size: inherit;
        background-position: center;
      }
    }

    .login-tips {
      display: inline-block;
      font-size: calc(10px - var(--default_font-size_offset));
      font-weight: 400;
      color: #aaaaaa;
      line-height: 15px;
      margin-bottom: 5px;
    }

    .el-form-item {
      margin-bottom: 15px !important;
    }

    .el-tabs__item {
      font-size: calc(20px - var(--default_font-size_offset));
      font-weight: 400;
      color: #777777;
      line-height: 27px;
      // transition: all 0.2s;
      &.is-active {
        // font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 29px;
      }
    }

    .el-tabs__active-bar {
      height: 2px;
      background: #b32574;
      border-radius: 1px;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .login-input .el-input__inner {
      font-size: calc(14px - var(--default_font-size_offset));
      font-weight: 400;
      color: #aaaaaa;
      line-height: 20px;
    }

    .login-button {
      width: 354px;
      height: 44px;
      background: #b32574;
      border-radius: 22px;
      font-size: calc(18px - var(--default_font-size_offset));
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 5px;

      > .el-icon-loading {
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    }
  }
}

.u-text {
  display: block;
  line-height: 28px;

  &.text-1 {
    text-indent: 32px;
  }
}
</style>

<template>
  <label :for="GUID" class="__oms-extend_el-tag-input el-input__inner fs-16"
         :class="{'hover':hover,'disabled':DISABLED,'readonly':READONLY}">
    <a class="tag" v-for="item in tagList">
      <span>{{ item }}</span>
      <i class="el-tag__close el-icon-close" v-if="!NOT_ALLOW" @click="onRemoveTag(item)"/>
    </a>
    <a class="__input-container">
      <input v-model="inputEmail" :id="GUID" ref="OMS_INPUT" type="text"
             @focus="hover=true" @blur="hover=false;onCreateTag();"
             @input="onKeyCreateTag" @keypress.enter="onCreateTag()"
             @paste="onPasteCheck"
             :disabled="NOT_ALLOW"
             @keydown="onKeyPressRemoveTag"/>
    </a>
    <!--    this;tagList;push -->
    <small v-if="!cloneSpanText && tagList.length <= 0" class="placeholder">{{ placeholder }}</small>
    <b ref="CLONE_SPAN" style="position: absolute;opacity: 0;">{{ cloneSpanText }}</b>
  </label>
</template>

<script>
import model from "@/mixins/model";

export default {
  name: "ElTagInput",
  inject: {
    omsRow: {
      default: "",
    },
    elForm: {
      default: "",
    },
    omsFormItem: {
      default: "",
    },
  },
  data() {
    return {
      tagList: this.value || [],
      cloneSpanText: "",
      hover: false,
      removeState: false,
      inputEmail: "",
    };
  },
  computed: {
    NOT_ALLOW() {
      return !!(this.omsRow.disabled || this.disabled || this.omsRow.readonly || this.readonly);
    },
    DISABLED() {
      return !!(this.omsRow.disabled || this.disabled);
    },
    READONLY() {
      return !!(this.omsRow.readonly || this.readonly);
    },
    GUID() {
      return this.$guid().replace("-", "");
    },
  },
  mixins: [model],
  mounted() {
    this.onUpdateInputSize();
  },
  beforeDestroy() {
    this.inputEmail = "";
  },
  props: {
    disabled: Boolean,
    readonly: Boolean,
    placeholder: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    cloneSpanText() {
      this.onUpdateInputSize();
    },
    inputEmail(val) {
      this.cloneSpanText = val;
    },
    tagList: {
      deep: true,
      handler() {
        this.dynamic = this.tagList;
      },
    },
    dynamic() {
      this.tagList = this.dynamic;
      this.$emit("change", this.dynamic);
    },
  },
  methods: {
    async onPasteCheck(event) {
      let clipboardData = event.clipboardData || window.clipboardData;
      let pasteData = this.inputEmail + clipboardData.getData("Text");
      await delay(0);
      if (pasteData.indexOf(";") >= 0) {
        this.onCreateTag(pasteData, true);
      }
      // event.currentTarget.value = '';
    },
    onRemoveTag(item) {
      const index = this.tagList.indexOf(item);
      if (index < 0) return;
      this.tagList.splice(index, 1);
    },
    async onKeyPressRemoveTag(event) {
      if (event.code === "Backspace" && this.tagList.length > 0 && this.inputEmail === "") {
        // 第二次回退确认删除
        if (this.removeState) {
          this.tagList.pop();
          await this.$nextTick();
          this.$refs.OMS_INPUT.focus();
          this.removeState = false;
        } else {
          this.removeState = true;
        }
        return;
      }

      this.removeState = false;
    },
    onKeyCreateTag(event) {
      const char = event.data;

      this.cloneSpanText = event.target.value;
      if (char === ";") {
        event.preventDefault();
        event.stopPropagation();
        this.onCreateTag();
      }
    },
    onCreateTag(str = this.inputEmail, stayInput = false) {
      // if (this.inputEmail[this.inputEmail.length - 1] === ";") {
      //   this.inputEmail = this.inputEmail.slice(0, this.inputEmail.length - 1);
      //   this.cloneSpanText = this.inputEmail;
      // }
      const tags = str.split(";").filter(_ => stayInput || Boolean(_)).map(x => x.trim());
      this.cloneSpanText = str;
      if (!str) return;
      let stay = "";
      if (stayInput) {
        stay = tags.pop();
      }
      this.inputEmail = stay;
      this.cloneSpanText = stay;
      tags.forEach(tag => this.tagList.push(tag));
    },
    async onUpdateInputSize() {
      this.$refs.CLONE_SPAN.style.fontSize = getComputedStyle(this.$refs.OMS_INPUT)["font-size"];
      await this.$nextTick();
      const {width} = this.$refs.CLONE_SPAN.getBoundingClientRect();
      this.$refs.OMS_INPUT.style.width = Math.max(width, 1) + "px";
    },
  },
};
</script>
<style scoped lang="scss">
.__oms-extend_el-tag-input {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: .2s linear;
  padding-left: 0;
  cursor: text;

  &.disabled {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    cursor: not-allowed;

    .tag {
      background-color: darken(#F5F7FA, 3%);
      padding-right: 15px;
    }
  }

  &.readonly {
    background-color: $INPUT_READ_ONLY_BACKGROUND;
    border-color: #E4E7ED;
    color: #C0C4CC;

    .tag {
      background-color: darken(#F5F7FA, 3%);
      padding-right: 15px;
    }
  }

  .__input-container {
    padding-left: 20px;
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
  }

  input {
    border: none;
    -webkit-appearance: none;
    line-height: 30px;
    padding: 5px 0;
    outline: 0;
    width: 2px;
    max-width: 100%;
  }

  &.hover {
    border-color: $BASE_COLOR;
  }

  .tag {
    line-height: 20px;
    padding: 5px 5px 5px 15px;
    margin: 5px 0 5px 10px;
    border-radius: 5px;
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    display: inline-block;

    > span {
      vertical-align: middle;
      display: inline-block;
      white-space: pre-wrap;
      word-break: break-all;
    }

    i {
      cursor: pointer;
      font-size: calc(12px - var(--default_font-size_offset));
      height: 16px;
      width: 16px;
      line-height: 16px;
      margin-left: 10px;
      vertical-align: middle;
      background-color: #c0c4cc;
      border-radius: 99px;
      text-align: center;

      &:hover {
        color: #fff;
        background-color: #909399;
      }
    }
  }

  .placeholder {
    font-size: calc(16px - var(--default_font-size_offset));
    position: absolute;
    padding: 5px 0 5px 20px;
    line-height: 30px;
    color: #aaa;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
  }
}
</style>

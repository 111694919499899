<script>
import ElSelectMenu from "element-ui/packages/select/src/select-dropdown";

export default {
  name: "ElSelectMenuExtend",
  extends: ElSelectMenu,
  watch: {
    "$parent.inputWidth"() {
      let propElement = this.$parent.element;
      let element;
      this.minWidth = this.$parent.$el.getBoundingClientRect().width + "px";

      if (typeof propElement === "function") {
        element = propElement();
      } else {
        element = propElement;
      }

      let rect = element?.getBoundingClientRect?.();
      if (rect) {
        this.minWidth = rect.width + "px";
      }
    }
  },

};
</script>

<template>
  <div :class="{'logo-black':!!black,'logo':!black}"/>
</template>

<script>
export default {
  name: "Logo",
  props: {
    black: Boolean,
  },
};
</script>

<style scoped lang="scss">
.logo, .logo-black {
  width: 92px;
  height: 44px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  display: inline-block;
}

.logo {
  background-image: url("../assets/images/logo/white.png");
}

.logo-black {
  background-image: url("../assets/images/logo/black.png");
}
</style>

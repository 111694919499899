<template>
  <div class="scrollbar-extend" @mousemove="onMouseMove"
       @mouseup="onMouseState($event,false)">
    <slot/>
    <div class="scrollbar-inner" @mousedown="clickTrackHandler" :class="{'is-absolute': !isWindow }">
      <div @mousedown="onMouseState($event,true)"
           ref="thumb"
           class="inner"
           :style="renderThumbStyle({move:moveY, size:sizeHeight,bar:{axis:'Y'}})"/>
    </div>
    <el-scrollbar></el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "VirualScrollbar",
  props: {
    scrollContainer: [Function, Element],
  },
  data() {
    return {
      wrap: window,
      body: document.body,
      sizeHeight: "",
      sizeWidth: "",
      moveY: "",
      mouseState: false,
      Y: 0,
    };
  },
  computed: {
    isWindow() {
      return this.wrap === window;
    }
  },
  mounted() {
    if (typeof this.scrollContainer === "function") {
      this.wrap = this.scrollContainer();
    } else if (this.scrollContainer) {
      this.wrap = this.scrollContainer;
    }
    this.update();
    this.onScroll();
    this.wrap.addEventListener("scroll", this.onScroll);
    this.wrap.addEventListener("resize", this.onScroll);
  },
  updated() {
    this.update();
  },
  destroyed() {
    this.wrap.removeEventListener("scroll", this.onScroll);
    this.wrap.removeEventListener("resize", this.onScroll);
  },
  methods: {
    onMouseState(e, bool) {
      this.mouseState = bool;
      if (bool) {
        document.onselectstart = () => false;
        this.Y = (this.$refs.thumb.offsetHeight - e.clientY) + e.currentTarget.getBoundingClientRect().top;
      } else {
        document.onselectstart = null;
      }
    },
    clickTrackHandler(e) {
      const offset = e.clientY;
      const thumbHalf = (this.$refs.thumb.offsetHeight / 2);
      const thumbPositionPercentage = ((offset - thumbHalf) * 100 / (this.wrap.innerHeight || this.wrap.clientHeight));
      // this.wrap[this.bar.scroll] = (thumbPositionPercentage * window.scrollY / 100);
      let body = this.body;
      if (this.wrap !== window) {
        body = this.wrap.children;
      }

      this.wrap.scrollTo((this.wrap.scrollX ?? this.wrap.scrollLeft ?? 0), (thumbPositionPercentage * body.scrollHeight / 100));
    },
    onMouseMove(e) {
      if (this.mouseState) {
        const prevPage = this.Y;
        const body = this.body;

        const offset = e.clientY;
        const thumbClickPosition = (this.$refs.thumb.offsetHeight - prevPage);
        const thumbPositionPercentage = ((offset - thumbClickPosition) * 100 / window.innerHeight);

        this.wrap.scrollTo(this.wrap.scrollX, (thumbPositionPercentage * body.scrollHeight / 100));
      }
    },
    onScroll() {
      this.moveY = (((this.wrap.scrollY ?? this.wrap.scrollTop) * 100) / (this.wrap.innerHeight || this.wrap.clientHeight));
    },
    renderThumbStyle({move, size, bar}) {
      const style = {};
      const translate = `translate${bar.axis}(${move}%)`;

      style.height = size;
      style.transform = translate;
      style.msTransform = translate;
      style.webkitTransform = translate;

      return style;
    },
    update() {
      let heightPercentage, widthPercentage;
      let body = this.body;

      if (this.wrap !== window) {
        body = this.wrap.children;
      }

      heightPercentage = ((this.wrap.innerHeight || this.wrap.clientHeight) * 100 / body.scrollHeight);
      widthPercentage = ((this.wrap.innerWidth || this.wrap.clientWidth) * 100 / body.scrollWidth);

      this.sizeHeight = (heightPercentage < 100) ? (heightPercentage + "%") : "";
      this.sizeWidth = (widthPercentage < 100) ? (widthPercentage + "%") : "";
    },
  },
};
</script>

<style scoped lang="scss">
.scrollbar-extend{
  overflow: hidden;
}
.scrollbar-inner {
  position: fixed;
  width: 6px;
  top: 0;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  right: 0;
  cursor: pointer;

  &.is-absolute {
    position: absolute;
  }

  .inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0;
    border-radius: 99px;
    background-color: rgba(144, 147, 153, 1);
    transition: background-color .1s linear;
    opacity: .5;


    &:hover {
      opacity: 1;
      background-color: rgba(144, 147, 153, 1);
    }
  }
}
</style>

import {MessageBox, Message} from "element-ui";
import {i18n}                from "@/app";

export function modalWarning(title, message, options) {
  message = message || title;
  return new Promise(resolve => {
    MessageBox.confirm(message, title, {
      type: "warning",
      showCancelButton: false,
      dangerouslyUseHTMLString: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      callback(action) {
        if (action === "close" || action === "cancel") {
          return resolve(false);
        }
        return resolve(true);
      },
      ...options,
    });
  });
}

export function messageWarning(title, message) {
  if (!message) {
    message = title;
    title = i18n.t("MessageBox.Hint");
  }
  Message({
    title,
    message,
    type: "warning",
    duration: 2000,
  });
}

export default {
  computed: {
    commonModalOptions() {
      return {
        type: "warning",
      };
    },
    ModalCloseLevel() {
      return {
        // 关闭键盘点击
        BlockKeyboardClick: 0b00,
        // 关闭蒙版点击
        BlockModalClick: 0b01,
        // 关闭所有点击关闭的方式
        BlockAllClick: 0b11,
      };
    },
    COMMON_TEXT() {
      return {
        // 提示
        tips: i18n.t("MessageBox.Hint"),
      };
    },
  },
  methods: {
    modalAlert(message, title) {
      return new Promise(resolve => {
        this.$alert(message, title || this.COMMON_TEXT.tips, {
          callback: action => {
            resolve();
          },
        });
      });
    },
    modalExtendDialog(message, title, options) {
      return new Promise(resolve => {
        this.$confirm(message, title || this.COMMON_TEXT.tips, {
          ...options,
          dangerouslyUseHTMLString: true,
          callback(action) {
            if (action === "close" || action === "cancel") {
              return resolve(false);
            }
            return resolve(true);
          },
        });
      });
    },
    modalWarning(message, title, options) {
      return new Promise(resolve => {
        const {callback, actionCallback, closeLevel, ...rest} = options || {};
        this.$confirm(message, title || this.COMMON_TEXT.tips, {
          ...rest,
          ...this.commonModalOptions,
          closeOnClickModal: !(closeLevel & this.ModalCloseLevel.BlockModalClick),
          closeOnPressEscape: !(closeLevel & this.ModalCloseLevel.BlockKeyboardClick),
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          async beforeClose(action, instance, done) {
            if (action === "cancel") {
              return done();
            }
            if (typeof callback === "function") {
              instance.confirmButtonLoading = true;
              const result = await callback();
              instance.confirmButtonLoading = false;
              if (result === false) {
                return;
              }
            }

            return done();
          },
        }).then(action => {
          if (actionCallback) {
            return resolve(actionCallback(action));
          }

          return resolve(true);
        }).catch(action => {
          if (actionCallback) {
            return resolve(actionCallback(action));
          }

          return resolve(false);
        });
      });
    },
    // 操作成功消息提示
    notifySuccess(message) {
      return this.$notify({
        title: i18n.t("MessageBox.Success"),
        message: message,
        type: "success",
        duration: 2000,
      });
    },
    // 操作消息提示
    notifyInfo(message) {
      return this.$notify({
        title: i18n.t("MessageBox.Hint"),
        message: message,
        type: "info",
        duration: 2000,
      });
    },
    // 错误提示
    notifyError(message) {
      return this.$notify({
        title: i18n.t("MessageBox.Error"),
        message: message,
        type: "error",
        duration: 2000,
      });
    },
    // 消息提示
    notifyWarning(message, title = i18n.t("MessageBox.Hint")) {
      //  消息通知弹窗
      return this.$notify({
        title: title, // 成功
        message: message,
        type: "warning",
        duration: 2000,
      });
    },
    async notifyAccessUser() {
      const ok = await this.modalExtendDialog(i18n.t("TranspertGlobalKeyText.authenticationText"), i18n.t("TranspertGlobalKey.reminder"), {
        customClass: "oms-warning-access-dialog",
        confirmButtonClass: "",
        confirmButtonText: i18n.t("TranspertGlobalKeyText.goTOAuthentication"),
        cancelButtonClass: "el-button--hollow",
        beforeClose(action, node, next) {
          next();
        },
      });
      if (ok) {
        this.$router.push({
          name: "UserInformation",
          params: {
            openAccess: true,
          },
        }).catch();
      }
    },
  },
};

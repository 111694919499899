var render, staticRenderFns
import script from "./ElCascaderExtend.vue?vue&type=script&lang=js&"
export * from "./ElCascaderExtend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d2b2f6",
  null
  
)

export default component.exports
import Vue              from 'vue'
import VueRouter        from 'vue-router'

Vue.use(VueRouter)
import BaseLayout       from '@/views/layout/Base'
import HeaderLayout     from '@/views/layout/Header'
import OrderLayout      from '@/views/user/OrderLayout'
import TrackViewer      from '@/views/share/trackViewer'
import Page404          from '@/views/state/Page404'
import RefreshComponent from "@/components/RefreshComponent";

const routes = [
  {
    name: "Refresh",
    path: "/refresh",
    component: RefreshComponent
  },
  {
    path: '',
    component: HeaderLayout,
    children: [
      {
        path: '',
        component: BaseLayout,
        children: [
          {
            path: '',
            name: 'Home',
            meta: {
              black: true,
              space: false,
              title: '运达人'
            },
            component: () => import('@/views/home/Home')
          },
          {
            path: 'home',
            redirect: '/'
          },
          {
            path: 'business/track/:type',
            name: 'BusinessTrack',
            component: () => import('@/views/business/Tracker'),
            meta: {
              title: '运达人-物流运输服务'
            }
          }
          // {
          //   path: "preview/template/:type",
          //   name: "PreviewTemplate",
          //   component: () => import("@/views/preview/View"),
          // },
        ]
      },
      {
        path: 'search',
        component: BaseLayout,
        children: [
          {
            path: '/list/:searchType',
            name: 'SearchList',
            component: () => import('@/views/search/SearchList')
          }
        ]
      },
      {
        path: '/track/share/:orderNum/:jobNo/:conNo',
        component: TrackViewer,
        meta: {
          title: '运达人-运踪轨迹'
        }
      },
      {
        path: '',
        name: 'PermissionRouteToCheckUser',
        // 检测认证
        component: () => import('@/views/layout/Permission'),
        children: [
          {
            path: 'freight/:type/query',
            name: 'FreightQuery',
            meta: {
              black: true,
              title: '运达人-运价查询',
              space: false
            },
            component: () => import('@/views/freight/Query')
          },
          {
            path: 'freight/:type/reOrder',
            name: 'ReOrder',
            component: () => import('@/views/freight/ReOrder')
          },
          {
            path: 'freight/:type/order',
            name: 'FreightOrder',
            redirect(to) {
              return {
                name: 'ReOrder',
                params: {
                  type: to.params.type
                },
                query: to.query
              }
            }
          }
        ]
      },
      {
        path: '/user',
        component: BaseLayout,
        children: [
          {
            path: 'order',
            name: 'UserOrderBase',
            component: OrderLayout,
            children: [
              {
                path: '',
                name: 'UserOrder',
                component: () => import('@/views/user/order/Index'),
                meta: {
                  title: '运达人-工作台'
                }
              },
              {
                path: 'booking/query',
                name: 'UserOrderBookingQuery',
                component: () => import('@/views/user/order/BookingQuery'),
                meta: {
                  title: '运达人-订舱申请'
                }
              },
              {
                path: 'freight/query',
                name: 'UserOrderFreightQuery',
                component: () => import('@/views/user/order/FreightQuery'),
                meta: {
                  title: '运达人-订单轨迹'
                }
              },
              {
                path: 'info/:trafficCd',
                name: 'UserOrderInfo',
                component: () => import('@/views/user/order/OrderInfo'),
                meta: {
                  title: '运达人-订舱详情'
                }
              },
              {
                path: 'track-info/:trafficCd',
                name: 'TrackInfo',
                component: () => import('@/views/user/order/TrackInfoRailway'),
                meta: {
                  title: '运达人-轨迹详情'
                }
              }
            ]
          },
          {
            path: '',
            name: 'UserLayout',
            component: () => import('@/views/user/UserLayout'),
            children: [
              {
                path: 'center',
                name: 'UserInformation',
                component: () => import('@/views/user/info/UserCenter'),
                meta: {
                  title: '运达人-账户设置'
                }
              }
            ]
          }
        ]
      },
      {
        path: '',
        name: 'PermissionRouteToCheckUser',
        // 检测认证
        component: () => import('@/views/layout/Permission'),
        children: [
          {
            path: '/workbench',
            name: 'Workbench',
            component: () => import('@/views/workbench/Workbench'),
            meta: {
              workbench: 'workPage'
            }
          },
          {
            path: '/todo',
            name: 'WorkOrderTodo',
            component: () => import('@/views/workbench/modules/WorkOrderTodo'),
            meta: {
              workbench: 'workPage'
            }
          },
          {
            path: '/orderList',
            meta: {
              workbench: 'workPage'
            },
            component: BaseLayout,
            children: [
              {
                path: 'order/:type',
                name: 'OrderList',
                component: () => import('@/views/workbench/modules/OrderList'),
                meta: {
                  title: '运达人-订单列表'
                }
              },
              {
                path: 'order/:type/info/:trafficCd',
                name: 'WorkbenchOrderInfo',
                component: () => import('@/views/workbench/modules/OrderInfo'),
                meta: {
                  title: '运达人-订单详情'
                }
              },
              {
                path: 'workorder/:type',
                name: 'WorkOrderList',
                component: () => import('@/views/workbench/modules/WorkOrderList'),
                meta: {
                  title: '运达人-工作单列表'
                }
              },
              {
                path: 'workorder/:type/info/:trafficCd',
                name: 'WorkbenchWorkOrderInfo',
                component: () => import('@/views/workbench/modules/WorkOrderInfo'),
                meta: {
                  title: '运达人-工作单详情'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: (to) => {
      console.log(to)
      return {
        name: 'Page404',
        query: {
          url: to.fullPath
        }
      }
      // return `/404?url=${encodeURIComponent(to.fullPath)}`
    }
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return {}
    }

    return savedPosition || { x: 0, y: 0 }
  }
})

export default router

import App                from "@/App.vue";
import Vue                from "vue";
import AppLink            from "@/components/AppLink";
import VueI18n            from "vue-i18n";
import store              from "@/store";
import router             from "@/router";
import ElementUI          from "element-ui";
import * as ElementExtend from "@/components/elementExtend";
import * as OmsContainer  from "@/components/container";
import LoadingSpan        from "@/components/LoadingSpan";
import OmsDialogExtend    from "@/components/omsExtend/OmsDialogExtend";
import OmsInput           from "@/components/omsExtend/OmsInput";
import OmsSelect          from "@/components/omsExtend/OmsSelect";
import OmsDatePicker      from "@/components/omsExtend/OmsDatePicker";
import OmsImg             from "@/components/omsExtend/OmsImg";
import apiMixin           from "@/mixins/api.method";
import Events             from "./plugins/events";

import "@/assets/extend/fonts.css";
import "@/directives/el-drag-dialog";
import "@/router/modules/permission";
import "@/directives/flex";
import "@/mixins";
import "@/api";
import "@/filter/displayDate";
import OmsControlGroup    from "@/components/OmsControlGroup";
// import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import dependent          from "@/mixins/dependent";
import OmsInputNumber     from "@/components/omsExtend/OmsInputNumber";
import OmsItem            from "@/components/omsExtend/global/OmsItem";
import OmsBaseCol         from "@/components/container/OmsBaseCol";
import "./styles/second.scss";
import OmsButton          from "@/components/omsExtend/global/OmsButton";
import VueSuperMethod     from "vue-super-call";

//todo: 还得将element-ui设置成中文化

const vueVersion = "2";

// switch (vueVersion){
//   case '3':
//     const app = createApp(App);
//     app.component("app-link", AppLink);
//     for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//       app.component(key, component)
//     }
//
// // app.mixin()
//     app.mixin({
//       methods: {
//         log(...d) {
//           console.log(...d);
//         },
//       },
//     });
//     break;
// }

Vue.use(Events);
Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.mixin(apiMixin);
Vue.prototype.$super = VueSuperMethod;

/** 覆写Element组件 **/
Vue.component("app-link", AppLink);
Vue.component("oms-img", OmsImg);
Vue.component("oms-row", OmsContainer.OmsRow);
Vue.component("oms-control-group", OmsControlGroup);
Vue.component("oms-input-number", OmsInputNumber);
Vue.component("oms-child", OmsContainer.OmsRow);
Vue.component("oms-form-item", OmsContainer.OmsFormItem);
Vue.component("oms-input", OmsInput);
Vue.component("oms-select", OmsSelect);
Vue.component("oms-date-picker", OmsDatePicker);
/** 2023-06-21新增 */
Vue.component("oms-container", Object.assign({}, OmsItem, {
  name: "OmsContainer"
}));
Vue.component("oms-col", Object.assign({}, OmsItem, {
  name: "OmsCol"
}));
Vue.component("oms-form", Object.assign({}, OmsItem, {
  name: "OmsForm"
}));
Vue.component("oms-item", OmsItem);
Vue.component("oms-button", OmsButton);
Vue.component("oms-base-col", OmsBaseCol);
/** 2023-06-21新增 */
Vue.component("el-checkbox-extend", ElementExtend.ElCheckboxExtend);
Vue.component("el-radio-extend", ElementExtend.ElRadioExtend);
Vue.component("el-select-extend", ElementExtend.ElSelectExtend);
Vue.component("el-select-remote-extend", ElementExtend.ElSelectRemoteExtend);
Vue.component("el-tag-input", ElementExtend.ElTagInput);

Vue.component("loading-span", LoadingSpan);
Vue.component("oms-dialog", OmsDialogExtend);

let base = VueI18n.prototype.mergeLocaleMessage;
VueI18n.prototype.mergeLocaleMessage = function (locale, localeMessageObject) {
  base.call(this, locale, localeMessageObject);
  this.mergeLocalFlattenMessage(locale, window.flattenObject(localeMessageObject));
};
VueI18n.prototype.mergeLocalFlattenMessage = function (locale, localeFlattenMessageObject) {
  if (!this._messageFlatten) {
    this._messageFlatten = {};
  }

  if (!this._messageFlatten[locale]) {
    this._messageFlatten[locale] = {};
  }
  Object.assign(this._messageFlatten[locale], localeFlattenMessageObject);
};


Vue.component("RenderVNode", {
  props: {
    node: Object,
  },
  render(h) {
    console.log("this.node", this.node);
    if (!this.node) return null;
    return this.node;
  }
});
export const i18n = new VueI18n({
  locale: window.localStorage.getItem("lang") || "zh-CN",
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});


let labelList = {};
Vue.prototype.$fKey = (label, startsWith) => {
  let key = labelList[label];

  if (!i18n._messageFlatten) return "";

  if (!key) {
    /*Object.keys(i18n._messageFlatten)*/
    ['zh-CN'].forEach(locale => {
      let flatten = i18n._messageFlatten[locale];
      key = Object.keys(flatten).find(key => (!startsWith || key.startsWith(startsWith)) && flatten[key] === label);

      if (key) {
        labelList[label] = key;
      }
    });
  }

  if (!key) {
    return label;
  }

  return key;
};

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");

const applyI18n = app.$t.bind(app);

Vue.prototype.$t = Vue.prototype.$t_reserve = function (label, ...params) {
  let key = this.$fKey(label);

  return applyI18n(key, ...params);
};

window.$$Application = app;

export default app;

<template>
  <div class="oms-img" :style="{
    width:WIDTH,
    height:HEIGHT,
    backgroundImage:`url('${src}')`,
  }">
    <img :src="src" alt="" @load="onLoad" @error="onError" :style="{
      width:WIDTH,
      height:HEIGHT,
    }">
  </div>
</template>

<script>
export default {
  name: "OmsImg",
  props: {
    src: String,
    width: [String, Number],
    height: [String, Number],
    auto: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    WIDTH() {
      if (this.width || this.naturalWidth) {
        if (typeof this.width === "string") {
          return this.width;
        }

        return `${this.width || this.naturalWidth}px`;
      }
    },
    HEIGHT() {
      if (this.WIDTH && this.auto) {
        return null;
      }

      if (this.height || this.naturalHeight) {
        if (typeof this.height === "string") {
          return this.height;
        }

        return `${this.height || this.naturalHeight}px`;
      }
    },
  },
  data() {
    return {
      naturalWidth: 0,
      naturalHeight: 0,
    };
  },
  methods: {
    onLoad(e) {
      const target = e.currentTarget;
      if (target) {
        this.naturalWidth = target.naturalWidth;
        this.naturalHeight = target.naturalHeight;
      }
    },
    onError() {
      console.log("error");
    },
  },
};
</script>

<style scoped lang="scss">
.oms-img {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 0;

  img {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
<template>
  <el-dialog :visible.sync="visibleExtend" :width="WIDTH" class="oms-dialog oms-dialog-extend none-header"
             :show-close="hideClose === true ? false : !clearEffect"
             :destroy-on-close="destroyOnClose"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             append-to-body
             @close="$emit('close',$event)"
             :class="{'oms-dialog-style-1':type === '1','clear-effect':clearEffect}">
    <template v-if="!clearEffect">
      <p v-if="title" class="fs-18 fc-333 mt-5 mlr-40 mb-10">{{ title }}</p>
      <slot name="insert"/>
      <div class="pb-70 plr-40 mt-30">
        <slot/>
      </div>
    </template>
    <slot v-else></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "OmsDialogExtend",
  props: {
    visible: Boolean,
    destroyOnClose: Boolean,
    title: String,
    width: [String, Number],
    clearEffect: Boolean,
    hideClose: Boolean,
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      visibleExtend: this.visible,
    };
  },
  watch: {
    visibleExtend(val) {
      if (val) {

      }
      this.$emit("update:visible", val);
    },
    visible(val) {
      this.visibleExtend = val;
    },
  },
  computed: {
    WIDTH() {
      if (typeof this.width === "number") {
        return this.width + "px";
      }
      return this.width;
    },
  },
};
</script>

<style lang="scss">
.oms-dialog-extend {
  input {
    height: 48px;
    font-size: calc(16px - var(--default_font-size_offset));
  }

  &.oms-dialog.oms-dialog-style-1 {
    .el-dialog__headerbtn {
      width: auto;
      height: auto;
    }
  }

  &.clear-effect {
    .el-dialog__header {
      padding: 0;
    }
  }
}
</style>

<template>
  <div v-loading="true" style="height:100vh;"
       :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0,0,0,.2)">

  </div>
</template>

<script>
export default {
  name: "RefreshComponent",
  data() {
    return {
      loadingText: "跳转中",
    };
  },
  created() {
    const {_name, loadingText, ...query} = this.$route.query;
    this.loadingText = loadingText;
    this.$router.replace({
      name: _name,
      query,
      params: this.$route.params,
    });
  }
};
</script>

<style scoped>

</style>

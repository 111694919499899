import Vue from "vue";
import LZUTF8 from "lzutf8";

export * from "@/utils/common";
export * from "@/mixins/requestAnimation";


export function parseMoney(value) {
    if (!value) return "0.00";
    let money = parseFloat(value).toFixed(2);
    let intPart = Number(money) | 0;
    let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");

    let floatPart = ".00";
    let value2Array = String(money).split(".");


    if (value2Array.length === 2) {
        floatPart = value2Array[1].toString();

        if (floatPart.length === 1) {
            return intPartFormat + "." + floatPart + "0";
        } else {
            return intPartFormat + "." + floatPart;
        }
    } else {
        return intPartFormat + floatPart;
    }
}

export function encodeDataForQuery(data) {
    const json = JSON.stringify(data);
    // const base64 = window.btoa(str);
    const str = LZUTF8.compress(json, {
        outputEncoding: "Base64",
    });
    // return encodeURIComponent(str);
    return str;
}

export function decodeQueryForData(baseString) {
    if (!baseString) return null;
    // const str = window.atob(baseString || "");
    // return JSONExtend.tryParse(decodeURIComponent(str), null);
    // const str = decodeURIComponent(baseString);
    const json = LZUTF8.decompress(baseString, {
        inputEncoding: "Base64",
        outputEncoding: "String",
    });
    // const data = window.atob(str);
    let result = JSONExtend.tryParse(json, null);
    console.log('当前解码结果:', result);
    return result;
}

Vue.mixin({
    methods: {
        // 顺序执行函数，不为true时中断.
        serialFunction(...functions) {
            return !functions?.some(func => func?.() !== true);
        },
    },
});

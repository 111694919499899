import Vue       from "vue";
import Element   from "element-ui";
import {i18n}    from "@/app";
import Cookies   from "js-cookie";
import elementCn from "element-ui/lib/locale/lang/zh-CN";
import elementEn from "element-ui/lib/locale/lang/en";
import elementTw from "element-ui/lib/locale/lang/zh-TW";
import elementRu from "element-ui/lib/locale/lang/ru-RU";

const SUPPORT_LOCALES = ["en", "zh-CN", "zh-TW", "ru"];

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
});

export default {
  namespaced: true, state: {
    // 为null不写死默认，而是交由互动设置默认
    locale: null, langJson: {}, i18n: null,
  }, // 同步
  mutations: {
    setupI18n(state, {i18n, locale}) {
      state.i18n = i18n;
      state.locale = locale;
    }, refreshDocumentTitle(state, locale) {
      window.mainTitle = {
        "zh-CN": "运达人", "zh-TW": "運達人", "en": "Transpert", "ru": "Transpert",
      }[locale] || "运达人";
      let app = window.$$Application;
      if (app) {
        let title = app.$router.currentRoute?.meta?.title || "";
        if (title) {
          let originTitle = title.split("-").pop();
          title = app.$t(originTitle);
          if (originTitle !== window.mainTitle) {
            title = " - " + title;
          } else {
            title = "";
          }
        }
        document.title = window.mainTitle + title;
      }
    }
  }, // 异步
  actions: {
    async INITIALIZE_LANGUAGES({state, commit, dispatch}, defaultLang = "zh-CN") {
      if (state.i18n) {
        return;
      }
      await import(`@/lang/zh-CN.json`).then(messages => {
        let locale = 'zh-CN';
        const langJson = messages.default;

        i18n.mergeLocaleMessage(locale, {
          ...langJson, ...elementCn
        });
      })

      commit("setupI18n", {i18n, locale: defaultLang});
      await dispatch("ASYNC_LOAD_LANG_JSON", defaultLang);
    },
    async ASYNC_LOAD_LANG_JSON({commit, state}, locale) {
      if (!locale) {
        locale = window.localStorage.getItem("lang");
      }
      if (!SUPPORT_LOCALES.includes(locale)) {
        locale = "zh-CN";
      }
      let language = {
        "zh-CN": "zh", "zh-TW": "tr", "en": "en", "ru": "ru",
      }[locale];
      Cookies.set("language", language);
      switch (language) {
        case "en":
          language = "c=en-US|uic=en-US";
          break;
        case "zh":
          language = "c=zh-CN|uic=zh-CN";
          break;
        case "tr":
          language = "c=zh-TW|uic=zh-TW";
          break;
        case "ru":
          language = "c=ru-RU|uic=ru-RU";
          break;
        default:
          language = "c=zh-CN|uic=zh-CN";
          break;
      }
      Cookies.set(".AspNetCore.Culture", language);
      Cookies.set("type", "TSP_PC");

      commit("setupI18n", {i18n, locale: locale});
      document.documentElement.setAttribute("lang", locale);

      await import(`@/lang/${locale}.json`).then(messages => {
        const langJson = messages.default;

        state.i18n.mergeLocaleMessage(locale, {
          ...langJson, ...{
            "zh-CN": elementCn, "en": elementEn, "zh-TW": elementTw, "ru": elementRu,
          }[locale]
        });
        /*        state.i18n.setLocaleMessage(locale, {
                  ...langJson, ...{
                    "zh-CN": elementCn,
                    "en": elementEn,
                    "zh-TW": elementTw,
                    "ru": elementRu,
                  }[locale]
                })*/
        state.i18n.locale = locale;
        commit("refreshDocumentTitle", locale);
      });
    },
  },
};

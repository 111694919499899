import {conInfoOptions, getCartType, GetHsCode, getServiceTypeOptions} from "@/api/options";
import request                                                         from "@/utils/request";

export default {
  props: {
    api: [String, Function],
    apiCallback: Function,
    apiParams: Object,
  },

  computed: {
    API() {
      return {
        Options: {
          getCartType,
          getServiceTypeOptions,
          conInfoOptions,
          getClerkList() {
            return request({
              url: "api/v1/dialog-mySalerlist",
              method: "get",
            });
          },
          GetHsCode,
        }
      };
    }
  },
};

<template>
  <track-viewer ref="TrackViewer"
                :con-no="$route.params.conNo"
                :job-no="$route.params.jobNo" />
</template>

<script>
// 运踪轨迹分享
import TrackViewer from "@/views/share/viewer.vue";

export default {
  name: "shareTrackViewer",
  components: { TrackViewer },
  computed: {
    SHARE_LINK() {
      return (window.location.host || "") + "route/path/content.asp";
    },
  },
  mounted() {
    this.saveTitle = document.title;
    //（${ this.railWayModel.Portl }-${ this.railWayModel.Portd }）
    document.title = `【${this.$t('TranspertGlobalKey.NeptuneLogisticsGroup')}】${this.$t('TranspertGlobalKey.orderNumber')}：${ this.$route.params.orderNum }，${this.$t('TranspertGlobalKey.CaseNo')}：${ this.$route.params.conNo }`;
    this.initialize();
  },
  destroyed() {
    document.title = this.saveTitle;
  },
  methods: {
    initialize() {
      setTimeout(() => {
        this.$refs.TrackViewer.visitTrackInfo();
      });
    },
  },
};
</script>

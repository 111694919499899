export function bindWatch(...rest) {
  let watch = {};
  for (let [variable, prop, deep = false, callback] of rest) {
    if (deep) {
      watch[variable] = {
        deep: true,
        handler(val) {
          if(prop === 'consList'){
            console.log(val,this[variable]);
            debugger;
          }
          callback && callback();
          this.$emit(`update:${prop}`, this[variable]);
        },
      };
      watch[prop] = {
        deep: true,
        handler(val) {
          if(prop === 'consList'){
            console.log(val,this[prop]);
            debugger;
          }
          this[variable] = this[prop];
        },
      };
    } else {
      watch[variable] = function () {
        callback && callback();
        this.$emit(`update:${prop}`, this[variable]);
      };
      watch[prop] = function () {
        this[variable] = this[prop];
      };
    }
  }

  return {
    watch,
  };
}

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: [
      String,
      Number,
      Object,
      Boolean,
      Array,
    ],
  },
  data() {
    return {
      dynamic: this.value,
    };
  },
  watch: {
    dynamic(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.$data.dynamic = val;
    },
  },
};
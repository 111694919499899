<template>
  <router-view/>
</template>

<script>
import store                          from "@/store";
import {messageWarning, modalWarning} from "@/mixins/notify";
import NProgress                      from "nprogress";
import {i18n}                         from "@/app";

export default {
  name: "OrderLayout",
  async beforeRouteEnter(to, from, next) {
    if (!store.getters.isLogin) {
      // messageWarning(this.$t('TranspertGlobalKeyText.TipsPleaseLogInFirstAndThenCheckTheRelevantLineServiceInformation'));
      messageWarning(i18n.t('TranspertGlobalKeyText.TipsPleaseLogInFirstAndThenCheckTheRelevantLineServiceInformation'));
      NProgress.done();
      // await modalWarning("提示", "请先进行登录", {
      //   confirmButtonText: "回到首页",
      // });
      return next({
        name: "Home",
      });
    }
    next();
  },
};
</script>

<style scoped>

</style>

<template>
  <component :labelWidth="inline ? labelWidth : null" :is="form ? 'el-form' : 'el-row'" :inline="inline"
             class="oms-row" v-bind="$attrs"
             :disabled="disabled || readonly"
             ref="FORM_ROW"
             :model="model"
             :rules="rules"
             :class="[{'width-auto':autoWidth,'clear-both':clearBoth,'readonly':readonly,'disabled':disabled},$i18n.locale]">
    <slot/>
  </component>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "OmsRow",
  provide() {
    return {
      omsRow: this || this.omsRow,
    };
  },
  inject: {
    omsRow: {
      default: "",
    },
  },
  props: {
    rules: Object,
    form: Boolean,
    autoWidth: Boolean,
    model: Object,
    labelWidth: {
      type: [String, Number],
      default: "100px",
    },
    clearBoth: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    readonly: Boolean,
    formItemMargin: [String, Number],
    formItemPadding: [String, Number],
  },
  computed: {
    row() {
      return this.$refs.FORM_ROW;
    },
  },
  data() {
    return {
      formItems: {},
    };
  },
  methods: {
    pushItem(prop, formItem) {
      this.formItems[prop] = formItem;
    },
    validate() {
      return new Promise(resolve => {
        this.row.validate?.(ok => {
          resolve(ok);
        });
      });
    },
    clearValidate() {
      this.row.clearValidate();
    },
    validateFields(...props) {
      return Promise.all(props.map(async prop => {
        return await new Promise(resolve => {
          this.row.validateField(prop, valid => {
            resolve(!valid);
          });
        });
      }));
    },
  },
};
</script>

<style lang="scss">
.oms-row {
  &.el-form--inline .el-form-item {
    margin: 0 80px 30px 0;
  }

  &.width-auto {
    .oms-form-item.el-form-item .el-form-item__content {
      width: auto;
    }
  }

  .el-form-item__label,
  .el-input,
  .el-select,
  .el-checkbox,
  .el-radio__label {
    font-size: calc(16px - var(--default_font-size_offset));
  }

  &.ru{
    .el-form-item__label,
    .el-input,
    .el-select,
    .el-checkbox,
    .el-radio__label {
      font-size: calc(12px - var(--default_font-size_offset));
    }
  }

  &.clear-both {
    &:before, &:after {
      content: unset;
    }
  }

  &.readonly {
    .el-input.is-disabled .el-input__inner {
      cursor: text;
      background-color: $INPUT_READ_ONLY_BACKGROUND;
      color: #333;
      border-color: #DCDFE6;
    }
  }

  &.disabled {
    .el-input.is-disabled .el-input__inner {
      background-color: #F5F7FA;
      border-color: #E4E7ED;
      color: #C0C4CC;
      cursor: not-allowed;
    }
  }
}
</style>

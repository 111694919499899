import request                          from "@/utils/request";
import {createCustomer, updateCustomer} from "@/api/customer";

export function countryCodeList(query) {
  return request({
    url: "/api/v1/country-list",
    method: "get",
    params: query,
    once: true,
    cache: {
      type: "local",
      ttl: 24 * 3600
    }
  });
}

export function sendSmsCode(query) {
  return request({
    url: "/api/v1/sendsmscode",
    method: "post",
    data: query
  });
}

// 手机号登录
export function tryLoginByCode(area, phone, code, userid, openid, shareId) {
  const query = {
    phone,
    code,
    countryCode: area
  };
  if (userid) {
    query["userid"] = userid;
  }
  if (openid) {
    query["openid"] = openid;
  }
  if (shareId) {
    query["shareid"] = shareId;
  }
  return request({
    url: "/api/v1/tryloginbycode",
    method: "post",
    data: query
  });
}

// 账号密码登录
export function tryLogin(name, password) {
  const login = {
    name,
    password
  };
  return request({
    url: "/api/v1/custrylogin",
    method: "post",
    data: {
      login: JSON.stringify(login)
    }
  });
}

// 微信登录
export function tryLoginByWechat(wxToken, param = {}) {
  if (!param["openid"] && param.hasOwnProperty("openid")) {
    delete param["openid"];
  }
  if (!param["userid"] && param.hasOwnProperty("userid")) {
    delete param["userid"];
  }
  return request({
    url: "/api/v1/wxtrylogin",
    method: "post",
    data: {
      wxToken,
      ...param
    }
  });
}

// 获取个人信息
export function getInfo() {
  return request({
    url: "/api/v1/cususerinfo",
    method: "get"
  });
}

// 退出登录
export function logout() {
  return request({
    url: "/api/v1/logout",
    method: "post",
    data: {
      logout: "true"
    }
  });
}

// 绑定手机号
export function bindPhone(query) {
  return request({
    url: "/api/v1/phonenum",
    method: "post",
    data: query
  });
}

export function bindAccount(query) {
  return request({
    url: "/api/v1/accountuser",
    method: "post",
    data: query
  });
}

// 校验身份验证窗口的验证码
export function checkSmsCode(query) {
  return request({
    url: "/api/v1/checksmscode",
    method: "post",
    data: query
  });
}

// 判断专属业务员
export function getShareInfo(shareId) {
  return request({
    url: "/api/v1/getshareinfo",
    method: "get",
    once: true,
    params: {
      shareId
    }
  });
}

// 绑定企业信息（实质=更新客商资料）
export function bindCustomer(query) {
  return createCustomer(query);
}

//解绑微信
export function unbindWechat(query) {
  return request({
    url: "api/v1/wechatuser",
    method: "delete",
    params: query
  });
}

//绑定微信
export function bindWechat(query) {
  return request({
    url: "api/v1/wechatuser",
    method: "post",
    data: query
  });
}

// 修改密码
export function changePassword(oldPassword, password) {
  return request({
    url: "/api/v1/updatepassword",
    method: "put",
    data: {
      oldpassword: oldPassword,
      password: password
    }
  });
}

// 获取我的订舱申请
export function getOrderList(data, type = "R") {
  const common = {
    keyword: "",
    startDate: "",
    endDate: "",
    orderState: "all",
    PortlCd: "",
    Portl: "",
    PortdCd: "",
    Portd: "",
    sort: "asc",
    sortName: "",
    lclState: "all"
  };
  const processor = data => {
    ["list", "Items"].forEach(prop => {
      if (data?.[prop]) {
        data[prop].forEach(item => {
          if (item.hasOwnProperty("PortlDisplay")) {
            item.Portl = item.PortlDisplay;
          }
          if (item.hasOwnProperty("PortdDisplay")) {
            item.Portd = item.PortdDisplay;
          }
        });
      }
    });
    return data;
  };
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railorderonline-list",
        method: "post",
        data: {
          ...common,
          ...data
        }
      }).then(processor);
    case "S":
      return request({
        url: "/api/v1/orderonlineztyt-list",
        method: "post",
        data: {
          ...common,
          ...data
        }
      }).then(processor);
    case "A":
      return request({
        url: "/api/v1/airorderonline-list",
        method: "post",
        data: {
          ...common,
          ...data
        }
      }).then(processor);
    case "M":
      return request({
        url: "api/v1/multitransorderonline-list",
        method: "post",
        data: {
          ...common,
          ...data
        }
      }).then(processor);
    case "T":
      return request({
        url: "/api/v1/highwayorderonline-list",
        method: "post",
        data: {
          ...common,
          ...data
        }
      }).then(processor);
  }
}

// 获取我的运踪轨迹
export function getRailwayTrackingList(query, type = "R") {
  const common = {
    filter: "",
    Atd: "",
    Ata: "",
    Portl: "",
    Portd: "",
    PortlCd: "",
    PortdCd: "",
    sort: "asc",
    sortName: "",
    blFed: false,
    ...query
  };
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railwaytracking-list",
        method: "post",
        data: common
      });
    case "S":
      return request({
        url: "/api/v1/seatracking-list",
        method: "post",
        data: common
      });
    case "A":
      return request({
        url: "/api/v1/airtracking-list",
        method: "post",
        data: common
      });
  }
}

// 获取订单详情
function _getOrderDetail(keyId, type = "R") {
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railorderonline-detail",
        method: "get",
        params: {
          keyId: keyId
        },
        debugger: true,
      });
    case "S":
      return request({
        url: "/api/v1/orderonlineztyt-detail",
        method: "get",
        params: {
          keyId: keyId
        }
      });
    case "A":
      return request({
        url: "/api/v1/airorderonline-detail",
        method: "get",
        params: {
          keyId: keyId
        }
      });
    case "T":
      return request({
        url: "/api/v1/highwayorderonline-detail",
        method: "get",
        params: {
          keyId: keyId
        }
      });
    case "M":
      return request({
        url: "/api/v1/multitransorderonline-detail",
        method: "get",
        params: {
          keyId: keyId
        }
      });
  }
}

export function getOrderDetail(keyId, type = "R") {
  return _getOrderDetail(keyId, type).then((rsp) => {
    if (rsp?.["costItems"]) {
      rsp.costItemList = rsp.costItems;
    }

    if (rsp) {

      if (!rsp.cntsList) {
        rsp.cntsList = (rsp.info.ConStat ?? "").split(",").filter(Boolean).map(x => {
          const [, Quantity, SizeType, LclId] = /([0-9]+)[Xx*]([0-9][0-9][A-Za-z][A-Za-z])[(（]([A-Za-z]{3})[）)]/ig.exec(x);
          return {
            Quantity,
            SizeType,
            LclId,
          };
        });
      }
    }

    return rsp;
  });
}

// 获取订舱详情里的工作单列表
export function getWorkOrderDetail(keyId, type = "R", {pageSize, pageIndex}) {
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railorderonline-detailworklist",
        method: "post",
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      });
    case "S":
      return request({
        url: "/api/v1/orderonlineztyt-detailworklist",
        method: "post",
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      });
    case "A":
      return request({
        url: "/api/v1/airorderonline-detailworklist",
        method: "post",
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      });
    case "T":
      return request({
        url: "/api/v1/highwayorderonline-detailworklist",
        method: "post",
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      });
    case "M":
      return request({
        url: "/api/v1/multitransorderonline-detailworklist",
        method: "post",
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      });
  }
}

// 获取工作单列表
export function getWorkOrderList(type = "R", data) {
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railorderonline-worklist",
        method: "post",
        data
      });
    case "S":
      return request({
        url: "/api/v1/orderonlineztyt-worklist",
        method: "post",
        data
      });
    case "A":
      return request({
        url: "/api/v1/airorderonline-worklist",
        method: "post",
        data
      });
    case "T":
      return request({
        url: "/api/v1/highwayorderonline-worklist",
        method: "post",
        data
      });
    case "M":
      return request({
        url: "/api/v1/multitransorderonline-worklist",
        method: "post",
        data
      });
  }
}

// 获取工作单详情
export function getWorkOrderListDetail(type = "R", keyId, jobNoIn) {
  switch (type) {
    case "R":
      return request({
        url: "/api/v1/railorderonline-worklistdetail",
        method: "get",
        params: {
          keyId,
          jobNoIn
        }
      });
    case "S":
      return request({
        url: "/api/v1/orderonlineztyt-worklistdetail",
        method: "get",
        params: {
          keyId,
          jobNoIn
        }
      });
    case "A":
      return request({
        url: "/api/v1/airorderonline-worklistdetail",
        method: "get",
        params: {
          keyId,
          jobNoIn
        }
      });
    case "T":
      return request({
        url: "/api/v1/highwayorderonline-worklistdetail",
        method: "get",
        params: {
          keyId,
          jobNoIn
        }
      });
    case "M":
      return request({
        url: "/api/v1/multitransorderonline-worklistdetail",
        method: "get",
        params: {
          keyId,
          jobNoIn
        }
      });
  }
}

// 确认工作单已完成
export function confirmWorkOrderFinish(jobNoIn) {
  return request({
    url: "api/v1/ordercompletesheet_cofirm",
    method: "put",
    data: {jobNoIn}
  });
}

// 获取我的待办列表
export function getWorkOrderTodoList(data) {
  return request({
    url: "/api/v1/messagelist",
    method: "post",
    data
  });
}

// 获取订舱 货物大类明细树
export function getCargoTree(params) {
  return request({
    url: "api/v1/category-list",
    method: "get",
    params,
    cache: {
      type: "local",
      ttl: 24 * 3600
    }
  });
  // .then((res) => {
  //   console.log(res);
  //   // 处理树结构数据
  //   return res?.Items?.map((item) => {
  //     item.children = []
  //     res?.SubItems.forEach((subItem) => {
  //       subItem?.Pid === item?.Id && item.children.push(subItem)
  //     })
  //     return item
  //   })
  // })
}

// 工作台-我的订单数据
export function getOrderCountData(type) {
  switch (type) {
    case "railway":
      return request({url: "api/v1/railorderonline-homeinfo", method: "get"});
    case "truck":
      return request({url: "api/v1/highwayorderonline-homeinfo", method: "get"});
    case "airport":
      return request({url: "api/v1/airorderonline-homeinfo", method: "get"});
    case "sea":
      return request({url: "api/v1/orderonlineztyt-homeinfo", method: "get"});
    case "multiTrain":
      return request({url: "api/v1/multitransorderonline-homeinfo", method: "get"});
  }
}

// 注销账户
export function deleteAccount() {
  return request({
    url: "/api/v1/delcususer",
    method: "delete"
  });
}

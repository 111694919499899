<template>
  <el-dialog
    :modal="false"
    :show-close="false"
    append-to-body
    class="unopen-dialog"
    :style="{ '--bkColor': backColor, '--opacity': opacity, '--fontColor': fontColor }"
    :visible.sync="visible"
  >
    <div slot="title" style="display: none"></div>
    <slot v-if="$slots.content" name="content"></slot>
    <div v-else>{{ $t_reserve(text) }}</div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UnopenDialog',
  components: {},
  props: {
    text: {
      type: String,
      default: '此功能暂未开放，敬请期待 ！！'
    },
    backColor: {
      type: String,
      default: '#000'
    },
    opacity: {
      type: String,
      default: '0.6'
    },
    fontColor: {
      type: String,
      default: '#fff'
    },
    dialogVisible: Boolean,
    showTime: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      visible: this.dialogVisible
    }
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.visible = val
        setTimeout(() => {
          this.$emit('update:dialogVisible', false)
          this.visible = false
        }, this.showTime)
      }
    }
  }
}
</script>

<style lang="scss">
.unopen-dialog .el-dialog {
  margin-top: 40vh !important;
  width: 290px;
  background: var(--bkColor);
  border-radius: 5px;
  opacity: var(--opacity);
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    width: 290px;
    height: 39px;
    padding: 0;
    text-align: center;
    line-height: 39px;
    font-size: calc(14px - var(--default_font-size_offset));
    font-weight: 400;
    color: var(--fontColor);
  }
}
</style>

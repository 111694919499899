var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"oms-img",style:({
  width:_vm.WIDTH,
  height:_vm.HEIGHT,
  backgroundImage:`url('${_vm.src}')`,
})},[_c('img',{style:({
    width:_vm.WIDTH,
    height:_vm.HEIGHT,
  }),attrs:{"src":_vm.src,"alt":""},on:{"load":_vm.onLoad,"error":_vm.onError}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
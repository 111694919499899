import Vue                     from "vue";
import TableSpecificationsEnum from "@/components/nbsExtend/nbsExtendTableDefine";
import {requestManage}         from "@/utils/request";
import {i18n}                  from "@/app";
import businessComputed        from "@/mixins/global/businessComputed";
import _                       from "lodash";

Vue.prototype.seekParentVNode = function (name) {
  let temp = this;

  while (temp) {
    if (temp.$options.name === name) {
      return temp;
    }
    temp = temp.$parent;
  }
};

Vue.mixin({
  data() {
    return {
      TSE: TableSpecificationsEnum,
    };
  },
  mixins: [businessComputed],
  computed: {
    GlobalCodeClass() {
      return {
        // 文件分类
        DOCTYPE: "DOCTYPE",
        // 进出口
        IEID: "IEID",
      };
    },
    GlobalOptions() {
      return this.$store.getters.options ?? {};
    },
    isRu() {
      return this.$i18n.locale === "ru";
    },
    isEn() {
      return this.$i18n.locale === "en";
    },
    isCn() {
      return this.$i18n.locale === "zh-CN";
    },
    isTw() {
      return this.$i18n.locale === "zh-TW";
    },
    IS_DEVELOPMENT() {
      return process.env.NODE_ENV === "development";
    },
    RequestManage() {
      return requestManage;
    },
    beforeRouteLeave() {
      requestManage.clear();
    },
    RoutePageOptions() {
      let componentName = this.RoutePage?.$options?.name;
      return {
        /*订舱页*/
        isReOrder: componentName === "ReOrder",
      };
    },
    RoutePage() {
      let name = this.$route.name;
      const match = this.$route.matched.find(x => x.name === name);

      if (match) {
        name = match.components?.default?.name;
      }
      if (name === this.$options.name) {
        return this;
      }

      return this.seekParentVNode(name) || {};
    },
  },
  destroyed() {
    if (window.refreshTitle) {
      window.refreshTitle = null;
    }
  },
  methods: {
    consoleLog(...args) {
      console.log(...args);
    },
    mergeBusinessModel(data = {}, type) {
      const model = this.createBusinessModel(type);
      const clone = _.cloneDeep(data);

      Object.keys(model).forEach(key => {
        clone[key] = clone[key] ?? model[key];
      });

      return clone;
    },
    // syncData: 需同步的对象 | source: 被同步的数据
    onGlobalSyncModel(syncData, source) {
      if (Array.isArray(syncData) && Array.isArray(source)) {
        if (syncData.length !== source.length) {
          let fill = [];
          if (syncData.length < source.length) {
            fill = new Array(source.length - syncData.length).fill("").map(() => ({}));
          }
          syncData.splice(source.length - 1, syncData.length - source.length, ...fill);
        }
        for (let i = 0, length = source.length; i < length; i++) {
          let left = syncData[i], right = source[i];

          _.merge(left, right ?? {});
        }

      } else if (typeof syncData === typeof source && typeof syncData === "object") {
        _.merge(syncData, source);
      } else {
        syncData = source;
      }
    },
    createBusinessModel(type) {
      const businessMode = this.getBusinessMode(type);

      // 暂时设定所有业务共用Model
      if (
        businessMode.IsRailWay ||
        businessMode.IsAirport ||
        businessMode.IsTruck ||
        businessMode.IsSea ||
        businessMode.IsMultiTrans
      ) {
        const costItemBaseModel = {
          "Rate": undefined,
          "Currcd": undefined,
          "UnitCd": undefined,
          "Quantity": undefined,
          "Amount": undefined,
        };
        const createCostItemList = () => {
          if (businessMode.IsTruck) {
            return [Object.assign({}, costItemBaseModel), Object.assign({}, costItemBaseModel)];
          } else if (businessMode.IsAirport) {
            return [Object.assign({}, costItemBaseModel)];
          }

          return [];
        };
        const createConList = () => {
          if (businessMode.IsTruck) {
            return [];
          }

          return [{
            SizeType: "20GP",
            Quantity: 0,
          }, {
            SizeType: "40GP",
            Quantity: 0,
          }, {
            SizeType: "40HQ",
            Quantity: 1,
          }];
        };

        return _.cloneDeep({
          costItemList: createCostItemList(),
          orderInfo: {
            AbbrName: "",
            ActLoadDate: "",
            ActualArrivalDt: "",
            PortdPortAmountStr: "",
            PortlPortAmountStr: "",
            AirOrderType: "",
            Ata: "",
            Atd: "",
            BookDate: "",
            BookingNo: "",
            CargoKind: "",
            CargoName: "",
            CargoNm: "",
            CarNo: "",
            CarNumIn: "",
            CarNumOut: "",
            CategoryId: "",
            CategoryName: "",
            CatTypeIn: "",
            CatTypeOut: "",
            CheckDt: "",
            CloseCastDt: "",
            CompleteDt: "",
            ConFeeRemark: "",
            ConsAddress: "",
            ConsContact: "",
            ConsDoc: "",
            ConsEmail: "",
            ConStat: "",
            ConsTel: "",
            ConsTelCode: "",
            CostId: "",
            CostRemark: "",
            CusClearPlace: "",
            CusClearPlaceCd: "",
            Cy: "",
            DeficitConfigStr: "",
            DeliveryPlaceDoc: "",
            DestDeliveringDt: "",
            DestReturnConDt: "",
            DestTocastDt: "",
            DraftAmount: "",
            DTHCAmount: "",
            DTHCCurrcd: "",
            EndBunkDt: "",
            EndConDt: "",
            EntrBizsubscribeEmail: "",
            EntryPort: "",
            EntryPortCd: "",
            EntryPortDisplay: "",
            Etad: "",
            EtPickConDt: "",
            Etsh: "",
            FileSD: "",
            HasDTHC: "F",
            HsCode: "",
            Id: "",
            IeId: "E",
            InDockDate: "",
            Intermediate: "",
            IntermediateCd: "",
            IntermediateDisplay: "",
            Intermodal: "",
            JobNoIn: "",
            LclId: "FCL",
            LeftPortDt: "",
            LineNormalRemark: "",
            LinePriceRemark: "",
            LinePriceValidDateFr: "",
            LinePriceValidDateTo: "",
            LineRemark: "",
            LoadDate: "",
            LoadPlace: "",
            Manager: "",
            ManagerCd: "",
            MeasDesc: "",
            NotiContact: "",
            NotiDoc: "",
            NotiEmail: "",
            NotiTel: "",
            NotiTelCode: "",
            OpDate: "",
            OrderRemark: "",
            OTHCAmount: "",
            OTHCCurrcd: "",
            OtherFeeRemark: "",
            OtherFeeStr: "",
            PackType: "",
            PassDate: "",
            PayWayId: "",
            PickConPlace: "",
            PickConPlaceCd: "",
            PieceWeight: undefined,
            Placed: "",
            PlacedCd: "",
            PlacedDisplay: "",
            Placer: "",
            PlacerCd: "",
            PlacerDisplay: "",
            Portd: "",
            PortdAmount: undefined,
            PortdCd: "",
            PortdCurrcd: "",
            PortdDisplay: "",
            Portl: "",
            PortlAmount: undefined,
            PortlCd: "",
            PortlDisplay: "",
            PriceRemark: "",
            RailPriceFclId: "",
            ReadyDate: "",
            ReLoadDt: "",
            ReturnPlace: "",
            ReturnPlaceCd: "",
            RwId: "",
            Saler: "",
            SalerAccount: "",
            SalerId: "",
            SendAddress: "",
            ShipAddress: "",
            ShipContact: "",
            ShipDoc: "",
            ShipEmail: "",
            ShipTel: "",
            ShipTelCode: "",
            SocId: "C",
            Sono: "",
            StateId: "",
            StorageRemark: "",
            SumCtns: undefined,
            SumCtnUnit: "",
            SumMeas: undefined,
            SumWeight: undefined,
            SvBookingId: "",
            SvCustomsId: "",
            SvDestDeliveringId: "",
            SvDestTocastId: "",
            SvInsureId: "",
            SvTrafficId: "Q",
            SvTrailerId: "",
            SvWareHouseId: "",
            TocastDate: "",
            ToEntryPortDt: "",
            TransDays: "",
            TransPort: "",
            TransPortCd: "",
            TransPortDisplay: "",
            UnladeAddress: "",
            TransRailFclPriceJson: ""
          },
          cntsList: createConList(),
        });
      }
    },
    setItemByUser(key, value) {
      sessionStorage.setItem((this.$store.getters.user.UserId || "") + "/" + key, value);
    },
    getItemByUser(key) {
      return sessionStorage.getItem((this.$store.getters.user.UserId || "") + "/" + key);
    },
    async omsConfirm(title, message, options) {
      return new Promise(async resolve => {
        try {
          await this.$confirm(title, message, {
            customClass: "oms-extend-confirm-dialog-style",
            ...options,
          });
          resolve(true);
        } catch (e) {
          resolve(false);
        }
      });
    },
  },
  watch: {
    "$i18n.locale"() {
      if (typeof this.initData === "function") {
        this.initData();
      }
    },
  }
});

export {default as requestAnimation} from "./requestAnimation";
export {default as resize}           from "./resize";
export {default as model}            from "./model";
export {default as rules}            from "./rules";
export {default as commonAnimate}    from "./commonAnimate";

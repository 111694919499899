import ls from 'localstorage-slim'
import {
  bindAccount,
  bindCustomer,
  bindPhone,
  bindWechat,
  getInfo,
  logout,
  tryLogin,
  tryLoginByCode,
  unbindWechat
} from '@/api/user'
import { updateCustomer } from '@/api/customer'
import { i18n } from '@/app'

const oneDay = 24 * 3600

const isDevelopment = process.env.NODE_ENV === 'development'

export function getToken() {
  ls.flush()
  return ls.get('token', { decrypt: !isDevelopment })
}

function removeToken() {
  ls.remove('token')
}

export default {
  namespaced: true,
  state: {
    token: '',
    info: {},
    // menu: [],
    centerType: ''
  },
  mutations: {
    SET_USER_INFO(state, info) {
      state.info = {
        ...info,
        Customer: {
          ...(info?.Customer || {}),
          State: info.CustomerState
        }
      }
    },
    // SET_MENU_INFO(state, menu) {
    //   state.menu = menu;
    // },
    SET_TOKEN(state, token) {
      state.token = token || ''
    },
    SET_CENTERTYPE(state, type) {
      state.centerType = type || ''
    }
  },
  actions: {
    async GET_CENTERTYPE({ commit, dispatch }, type) {
      if (!type) return
      commit('SET_CENTERTYPE', type)
    },
    async BIND_WECHAT({ commit, dispatch }, info) {
      const ok = await bindWechat(info)

      if (window.isNullOrUndefined(ok) || ok?.success === false) {
        return ok?.message || i18n.t('RequestMessage.GetWechatInfoError')
      }

      return await dispatch('GET_INFO', true)
    },
    async UNBIND_WECHAT({ dispatch }, info) {
      const ok = await unbindWechat(info)

      if (window.isNullOrUndefined(ok) || ok?.success === false) {
        return ok?.message || i18n.t('RequestMessage.GetBindErrorInfo')
      }

      return await dispatch('GET_INFO', true)
    },
    async BIND_CUSTOMER({ commit, dispatch }, info) {
      let ok
      if (info?.customer?.CusCd) {
        ok = await updateCustomer(info)
      } else {
        ok = await bindCustomer(info)
      }

      if (window.isNullOrUndefined(ok) || ok?.success === false) {
        return ok?.message || i18n.t('RequestMessage.BindBusinessInfoError')
      }

      return await dispatch('GET_INFO', true)
    },
    async BIND_ACCOUNT({ commit, dispatch }, info) {
      const ok = await bindAccount(info)

      if (!ok || ok?.success === false) {
        return ok?.message || i18n.t('RequestMessage.BindError')
      }

      return await dispatch('GET_INFO', true)
    },
    async BIND_PHONE({ commit, dispatch }, info) {
      const ok = await bindPhone({
        phone: info.phone,
        countryCode: info.area,
        code: info.code
      })

      if (!ok || ok?.success === false) {
        return ok?.message || i18n.t('RequestMessage.BindError')
      }

      return await dispatch('GET_INFO', true)
    },
    async GET_INFO({ commit, state }, refresh = false) {
      let user = {}
      for (let i = 0; i < 2; i++) {
        if (!refresh && user.Saler && user.SalerWxQrCode) {
          break
        }
        if (!user?.UserId || !user?.Saler || (user?.Saler && !user?.SalerWxQrCode) || refresh) {
          user = await getInfo()
        }

        if (refresh) {
          break
        }
      }

      if (!user || (user?.success === false && process.env.NODE_ENV !== 'development')) {
        removeToken()
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        // commit("SET_MENU_INFO", []);
        return user?.message || ''
      }

      commit('SET_USER_INFO', user)
      return ''
    },
    async LOG_OUT({ commit }, type = 'network') {
      let ok = true
      if (type === 'network') {
        await delay(700)
        ok = await logout()
      }
      if (ok) {
        removeToken()
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        // commit("SET_MENU_INFO", []);

        return
      }

      return OK?.message || i18n.t('RequestMessage.LoginError')
    },
    async LOGIN_SUCCESS({ commit, dispatch }, res) {
      let token = res.token

      if (!token) {
        await dispatch('LOG_OUT')
        return i18n.t('RequestMessage.LoginErrorMessage')
      }

      commit('SET_TOKEN', token)
      ls.set('token', token, {
        // 1天有效期
        ttl: oneDay,
        encrypt: !isDevelopment
      })

      await delay(700)
      return await dispatch('GET_INFO', true)
    },
    async WECHAT_LOGIN({ commit, dispatch }, wechatInfo) {
      if (wechatInfo.success === false) {
        return result.message
      }

      return dispatch('LOGIN_SUCCESS', wechatInfo)
    },
    async USER_LOGIN({ commit, dispatch }, loginInfo) {
      let token
      const { user, type, ShareId } = loginInfo

      await delay(700)
      if (type === 'login.phone') {
        const result = await tryLoginByCode(user.area, user.phone, user.verifySmsCode, '', '', ShareId)

        if (result.success === false) {
          return result.message
        }

        token = result.token
      } else if (type === 'login.account') {
        const result = await tryLogin(user.phone, user.verifySmsCode)

        if (result.success === false) {
          return result.message
        }

        token = result.token
      }

      if (!token) {
        await dispatch('LOG_OUT')
        return i18n.t('RequestMessage.LoginErrorMessage')
      }

      commit('SET_TOKEN', token)
      ls.set('token', token, {
        // 1天有效期
        ttl: oneDay,
        encrypt: !isDevelopment
      })

      return await dispatch('GET_INFO', true)
    }
  }
}

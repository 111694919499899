<template>
  <el-select v-model="DYNAMIC_COMPUTED" @visible-change="onRetryGetList" filterable class="select" :element="element">
    <el-option v-for="item in countryTelCodeList"
               class="flex between"
               :disabled="item.value === null || item.value === undefined"
               :key="item.name + item.value" :value="'+'+item.value">
      <!-- 中文 -->
        <span v-if="$i18n.locale == 'zh-CN'">
          {{ item.CountryNameChs }}
        </span>
      <!-- 繁体 -->
        <span v-if="$i18n.locale == 'zh-TW'">
          {{ item.CountryNameCht }}
        </span>
      <!-- 英文 -->
        <span v-if="$i18n.locale == 'en'">
          {{ item.CountryName }}
        </span>
      <!-- 俄语 -->
        <span v-if="$i18n.locale == 'ru'">
          {{ item.CountryNameRus || item.CountryName }}
        </span>
      <span>
        {{ item.value ? `+${item.value}` : "" }}
      </span>
    </el-option>
  </el-select>
</template>

<script>
import {model}          from "@/mixins";
import {mapState}       from "vuex";
import ElSelectExtendEx from "@/components/elementExtend/ElSelectExtendEx";

export default {
  name: "SelectAreaNumber",
  mixins: [model],
  components:{
    ElSelect:ElSelectExtendEx,
  },
  props:{
    element:[Object,Function],
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(["countryTelCodeList"]),
    DYNAMIC_COMPUTED: {
      get() {
        const area = this.dynamic;
        if (area) {
          return `+${area}`;
        }

        return "+86";
      },
      set(val) {
        if (val[0] === "+") {
          val = val.slice(1, val.length);
        }

        this.dynamic = val;
      },
    },
  },
  methods: {
    onRetryGetList(visible) {
      if (visible && this.countryTelCodeList.length <= 0) {
        this.initialize();
      }
    },
    initialize() {
      // 获取区号
      this.$store.dispatch("SET_COUNTRY_TEL_CODE_LIST").then(() => {
        this.$emit("change", this.countryTelCodeList.find(x => +x.value === (+this.value || 86))?.value);
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <el-popover popper-class="__el-select-extend_popover-container">
    <slot/>
    <el-input slot="reference" v-bind="$attrs">
      <a v-if="dialog" slot="suffix" href="javascript:void(0);"
         @click="visible=true;">
        <el-icon name="search" class="p-5"/>
        <el-dialog append-to-body v-el-drag-dialog
                   v-bind="dialogOptions" class="__el-select-extend__dialog"
                   :close-on-click-modal="false"
                   :visible.sync="visible">
          <div slot="title" v-if="title">
            {{ title }}
          </div>
          <slot name="title" slot="title"/>
        </el-dialog>
      </a>
    </el-input>
  </el-popover>
</template>

<script>
export default {
  name: "ElSelectExtend",
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    title: String,
    dialogOptions: Object,
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="scss">
.__el-select-extend__dialog {
  font-size: calc( 18px - var(--default_font-size_offset));

  .el-dialog__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #DCDFE6;
  }
}

.__el-select-extend_popover-container {
  padding: 0;
}
</style>
